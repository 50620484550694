.letter-to-me-container {
  display: flex;
  flex-wrap: wrap; /* Allow columns to stack on smaller screens */
  /* height: 95vh; Full viewport height */
  width: 100%;
  overflow: hidden;
}

.left-column {
  position: relative;
  width: 48%;
  background-image: url('../../images/iitkgp.jpg'); 
  background-color: black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.desc-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f7f9f8;
}

.left-column h2 {
  position: relative;
  margin-top: 16px;
  font-size: 2.7rem;
  font-weight: 900;
  margin-bottom: 28px;
  text-align: center;
}

.left-column h2 img {
  position: absolute;
  top: -40px;
  left: -60px;
  width: 100px;
  height: 80px;
  transform: rotate(340deg);
}

.left-column p {
  font-size: 1.1rem;
  margin: 10px 0;
  line-height: 1.5;
}

.right-column {
  width: 52%;
  background-color: #1c272b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%; /* Ensure the form container takes up full height */
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  height: 100vh; /* Make the form take up the full viewport height */
  box-sizing: border-box; /* To ensure padding doesn’t affect the height */
}

form input,
form textarea,
form button {
  background-color: white;
  width: 100%;
  padding: 12px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 1rem;
}

.box1 {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;
}

#sendDate,
#rollNo {
  width: 60%;
}

#message {
  height: 220px;
}

form button {
  background-color: #68d128;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #4eda0d;
}

.error-message {
  color: #ff4d4d;
  font-size: 0.9rem;
}

.server-message {
  color: #256b27;
  font-size: 0.9rem;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .letter-to-me-container {
    height: auto; /* Allow height to adjust */
  }

  .left-column,
  .right-column {
    width: 100%; /* Full width for each column */
    height: auto; /* Adjust height automatically */
  }

  /* Hide left column on smaller screens */
  .left-column {
    display: none;
  }

  form {
    padding: 0 40px; /* Adjust padding for medium screens */
    height: 100vh; /* Ensure form takes up full height */
  }

  #message {
    height: 200px; /* Adjust text area height */
  }
}

@media (max-width: 768px) {
  .letter-to-me-container {
    flex-direction: column; /* Stack columns vertically */
  }

  /* Hide left column on smaller screens */
  .left-column {
    display: none;
  }

  .right-column {
    width: 100%; /* Full width for the form */
  }

  form {
    padding: 0 20px; /* Reduce padding for smaller screens */
    height: 100vh; /* Ensure form takes up full height */
  }

  #message {
    height: 180px; /* Adjust text area height */
  }
}

@media (max-width: 480px) {
  .left-column {
    display: none; /* Completely hide the left column */
  }

  .right-column {
    width: 100%; /* Full width for the form */
  }

  form input,
  form textarea,
  form button {
    font-size: 0.9rem; /* Adjust input sizes */
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .box1 {
    flex-direction: column; /* Stack inputs vertically */
    gap: 10px;
    width: 100%;
  }

  #sendDate,
  #rollNo {
    width: 100%; /* Make inputs full-width on small screens */
  }
}
