body {
  margin: auto !important;
}

.electionHeaderImg {
  background-image: url("../../images/display/tsg_new.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 25rem;
}

.electionsContainer {
  display: block;
  margin: 7vh auto 0;
  min-height: 80vh;
  font-family: "Lato";
}

.electionsContainer p {
  letter-spacing: 1.5px;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 125%;
  font-family: "Lato";
  margin: 2rem 0rem;
  justify-content: center !important;
}

.electionBody {
  color: rgba(255, 255, 255, 0.8);
  display: block;
  margin: 2rem auto 0;
  text-align: center;
}

.electionBody>h5 {
  color: #f1c40f;
  font-weight: 300;
}

/* notices */
.electionBody .notice {
  /* border: 1px solid; */
  /* border-color: #f1c40f; */
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  width: 90%;
  margin: 1rem auto;
}

.notices {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.electionBody button {
  font-size: 1rem;
  border: 1px solid;
  border-color: #f1c40f;
  color: #fff;
  background-image: linear-gradient(45deg, #f1c40f 50%, transparent 50%);
  width: 75%;
  margin-left: 10px;
  margin-right: 10px;
  background-position: 100%;
  background-size: 400%;
  transition: background 200ms ease-in-out;
}

.btn_interiit {
  background-image: linear-gradient(45deg, #f1c40f 50%, transparent 50%);
  background-position: 100%;
  background-color: #f1c40f !important;
}

.tab .active {
  background-color: #F1C40F;
}

.nomination-btn {
  display: flex !important;
  flex-wrap: wrap !important;
  margin: 1rem !important;
  width: auto;
}

.electionBody button a {
  color: inherit;
}

.electionBody button:hover {
  color: #000;
  background-position: 0;
}

.buttonGroup {
  display: inline-block;
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

/* Elections Candidates*/
.postSelector {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

select.selectButton {
  font-size: 1.25rem;
  border: none;
  background: #eee;
  color: black;
  padding: 1rem;
  border-radius: 5px;
  background-color: #ddd;
  max-width: 60%;
  margin: auto;
}

.candidateContainer {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.googleForm {
  width: 100%;
  height: 100vh;
}

.loadingStateLabel {
  color: gray;
  margin: 2rem 1rem !important;
}