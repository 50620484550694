@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto:700);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400&display=swap);
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  border-radius: 0.4rem;

  z-index: 5555;
}
::-webkit-scrollbar-thumb {
  border-radius: 0.3rem;
  background-color: #949494;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #b9b9b9;
}
body,
body * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.thumbnails img {
  width: 10% !important;
}
.layout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
.active {
  background-color: transparent !important;
}

.activeSide {
  background-color: blue;
}
.contacts_content__1wT_L {
  padding: 2 rem;
  margin-right: 0.05 rem;
  max-height: 90 vh;
  overflow-y: scroll;
  width: 100% !important;
}
/* .main-container {
  padding-top: 100px;
  display: flex;
  flex-direction: column;
} */

section {
  max-width: 1500px;
  align-self: center;
}

.pageHeading {
  margin-top: 5rem;
  margin-bottom: 4rem;
  font-size: 2.5rem;
  text-align: center;
  color: #fff;
}

.sectionHeading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  color: #fff;
}

section .section-heading {
  font-size: 34px;
  margin: 20px 0;
  text-align: center;
}

section .description {
  font-size: 18px;
}

section blockquote {
  margin: 15px 0;
  padding: 10px;
  border: 0;
  border-left: 4px;
  border-style: solid;
  border-color: rgb(117, 117, 117);
}
@media screen and (max-width: 1200px) {
  html {
    font-size: 16px;
  }
}
@media screen and (max-width: 960px) {
  html {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
@media screen and (max-width: 576px) {
  html {
    font-size: 10px;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --background-gradient: linear-gradient(30deg, #f39c12 30%, #f1c40f);
  --gray: #34495e;
  --darkgray: #2c3e50;
}
select {
  /* Reset Select */
  -webkit-appearance: none;
          appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  /* Personalize */
  flex: 1 1;
  padding: 0 1em;
  color: #fff;
  background-color: var(--darkgray);
  background-image: none;
  cursor: pointer;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select wrapper */
.select {
  position: relative;
  display: flex;
  width: 18em;
  height: 3em;
  border-radius: 0.25em;
  overflow: hidden;
  margin: auto;
}
/* Arrow */
.select::after {
  content: "\25BC";
  position: absolute;
  top: 0;
  right: 0;
  padding: 1em;
  background-color: #34495e;
  transition: 0.25s all ease;
  pointer-events: none;
}
/* Transition */
.select:hover::after {
  color: #f39c12;
}
.withTable {
  margin: auto;
  padding: 0;
}
.withTable .table-container {
  font-size: 1.2rem;
}
.withTable .table-container table {
  margin-bottom: 1rem;
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 10px rgba(44, 52, 60, 0.6);
}
.withTable .table-container th,
.withTable .table-container td {
  padding: 1rem;
  padding-right: 0.25rem;
  color: #000;
}
.withTable .table-container th .medal::before,
.withTable .table-container td .medal::before {
  content: " \25CF";
  font-size: 30px;
}
.withTable .table-container th .gold,
.withTable .table-container td .gold {
  color: gold;
}
.withTable .table-container th .silver,
.withTable .table-container td .silver {
  color: silver;
}
.withTable .table-container th .bronze,
.withTable .table-container td .bronze {
  color: #cd7f32;
}
.withTable .table-container th {
  text-align: left;
  color: #fff;
}
.withTable .table-container thead {
  position: -webkit-sticky;
  position: sticky;
  top: 1;
}
.withTable .table-container thead th {
  background-color: #e7bd12;
}
.withTable .table-container tbody {
  margin-top: 2rem;
}
.withTable .table-container tbody tr {
  background-color: #fff;
}
.withTable .table-container tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}
.withTable .table-container tbody tr:last-of-type {
  border-bottom: 2px solid #04d6ac;
}
.withTable .table-container tbody #overall:first-of-type {
  background-color: hsl(168, 76%, 80%);
}
.withTable .table-container tbody td {
  position: relative;
}
.withTable .table-container tbody td ul {
  list-style-type: none;
}
.login-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .login-screen__form {
    width: 30vw;
    padding: 1.5rem;
    background: #c5be9f3b;
    border-radius: 20px;
  }
  
  .login-screen__form:hover {
    box-shadow: 4px 4px 20px #fff1766b, -4px -4px 20px #fff1766b;
  }
  
  .login-screen__title {
    text-align: center;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    color: #f1c40f;
  }
  
  .login-screen__forgotpassword {
    font-size: 0.7rem;
  }
  
  .login-screen__subtext {
    font-size: 0.7rem;
    display: block;
    margin: 0.5rem 0;
  }
  
  .form-group {
    display: flex;
    justify-content: center;
    margin: 1rem;
  }
  
  input {
    padding: 10px;
    border-radius: 8px;
    border-width: 0;
    width: 90%;
    background: #ecf0f3;
    font-size: 1rem;
  }
  
  .form-button {
    display: flex;
    justify-content: center;
  }
  
  .form-button button {
    background-color: blue;
    border-width: 0;
    color: #ecf0f3;
    font-size: 1rem;
    padding: 0.5rem;
    margin-top: 0.4rem;
    border-radius: 0.5rem;
    width: 20%;
  }
  
  .form-button button:hover {
    cursor: pointer;
    box-shadow: 4px 4px 2px #21201b;
    background-color: #5f5fc2;
    color: #0000ff;
    font-weight: bold;
  
  }
  .error-message{
    display: flex;
    justify-content: left;
    margin: 1rem;
    color: red;
  }
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

footer {
  width: 100%;
  background-color: black;
  margin: 0;
  padding: 0;
}

.footer_footerContainer__36auL {
  width: 95%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  height: 27.5vh;
  padding-bottom: 1rem;
}

.footer_footerLine__1yb95{
  width: 70%;
  background-color: transparent;
  border-bottom: 1px solid white;
  margin: 2rem auto;
}

.footer_tsgContainer__2kkNu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 32.5%;
  border-right: 3px dashed grey;
}
.footer_imgContainer__3Cg1C {
  height: 40%;
  margin-bottom: 0.5rem;
}
.footer_imgContainer__3Cg1C img {
  object-fit: cover;
}
.footer_tsgContainer__2kkNu .footer_tsgDescription__2TnK7 {
  color: white;
}
.footer_tsgContainer__2kkNu .footer_tsgDescription__2TnK7 .footer_tsgHeading__2LzOw {
  font-size: 1.25rem;
}
.footer_tsgContainer__2kkNu .footer_tsgDescription__2TnK7 .footer_address__3nJFC {
  text-align: center;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.footer_iconsContainer__65YCX {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0rem 20%;
  justify-content: space-around;
}
.footer_iconsContainer__65YCX a {
  color: #fff;
  font-size: 1.5rem;
  margin: 0.25rem;
}
.footer_iconsContainer__65YCX a:hover {
  transition: ease-out 0.5s;
  color: #f1c40f;
}
.footer_linksContainer__2hAVj {
  flex-grow: 2;
  padding: 0rem;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: space-around;
  border-right: 3px dashed grey;
}

.footer_linksContainer__2hAVj p{
  text-align: center;
  border-bottom: 1.5px dotted grey;
  padding: 0.5rem 0rem;
  margin-bottom: 0rem;
  position: relative;
}

.footer_linksContainer__2hAVj p span{
  visibility: hidden;
  width: 7.5rem;
  background-color: grey;
  color: #fff;
  font-size: 0.75rem;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);
  right: 85%;
}

.footer_linksContainer__2hAVj p:hover span{
  visibility: visible;
}

.footer_linksContainer__2hAVj p a {
  padding: 0;
  color: #fff;
  text-decoration: none;
  text-transform: capitalize;
}
.footer_linksContainer__2hAVj p a:hover {
  color: #f1c40f;
  font-weight: 500;
}
.footer_contactSection__rnqzS {
  padding-left: 3rem;
  width: 32.5%;
  margin: auto;
  display: flex;
  flex-direction: column;
  text-align: center;
  font-weight: 700;
}
.footer_contactSection__rnqzS span:first-of-type {
  font-size: 2.5rem;
  color: #fff;
}
.footer_contactSection__rnqzS span:last-of-type {
  font-size: 2rem;
  color: #f1c40f;
  margin-bottom: 1rem;
}
.footer_contactButton__XTLdr {
  width: 50%;
  color: white;
  transition: 0.2s;
  font-size: 1rem;
  max-width: 100%;
  padding: 1.25rem 1rem;
  margin-bottom: 1rem;
  text-decoration: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #f1c40f;
  color: #fff;
  background-image: linear-gradient(45deg, #f1c40f 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  transition: background 300ms ease-in-out;
}
.footer_contactButton__XTLdr:hover {
  background-position: 0;
}

.footer_copyright__33fbA{
  text-align: center;
  color: white;
  margin: 1rem auto;
  padding-top: 20px;
  padding-bottom: 15px;
}

.footer_copyright__33fbA a{
  color: #f1c40f;
}

.footer_copyright__33fbA a:hover{
  color: #f6d965;
}

@media screen and (min-width: 1460px) {
  .footer_linksContainer__2hAVj p{
    font-size: 1.25rem;
  }
}

@media screen and (max-width : 768px){
  .footer_imgContainer__3Cg1C {
    display: none;
  }
}

@media screen and (max-width: 1400px) { 
  .footer_contactSection__rnqzS {
    padding-left: 0;
  }

  .footer_footerContainer__36auL {
    height: auto ;
    flex-direction: column;
  }
  .footer_tsgContainer__2kkNu {
    width: 100%;
    border-right: none;
    border-bottom: 3px dashed grey;
  }
  .footer_tsgContainer__2kkNu .footer_tsgDescription__2TnK7 .footer_tsgHeading__2LzOw {
    text-align: center;
    font-size: 2rem;
  }
  .footer_tsgContainer__2kkNu .footer_tsgDescription__2TnK7 .footer_address__3nJFC {
    font-size: 1.25rem;
  }
  .footer_iconsContainer__65YCX {
    width: 60%;
    margin-bottom: 2rem;
  }
  .footer_iconsContainer__65YCX a {
    font-size: 1.5rem;
  }
  .footer_linksContainer__2hAVj {
    border-bottom: 3px dashed grey;
    margin: 0 auto;
    padding: 1.5rem 0;
    border-right: none;
    width: 100%;
  }

  .footer_linksContainer__2hAVj p a {
    padding: 0;
    text-transform: capitalize;
    font-size: 1rem;
  }
  .footer_contactSection__rnqzS {
    margin-top: 1rem;
    width: 100%;
    flex-direction: column;
    text-align: center;
  }
  .footer_contactButton__XTLdr {
    width: 40%;
    margin: auto;
  }
  .footer_contactSection__rnqzS span:last-of-type {
    margin-bottom: 1rem;
  }
  .footer_contactSection__rnqzS button {
    margin-bottom: 1rem;
  }
}



/* header */
* {
  box-sizing: border-box;
}
.navbar_navbarContainer__1YDM6 {
  width: 100%;
  position: fixed;
  z-index: 99;
}
.navbar_navbar__2x_1U {
  height: 6vh;
  background: transparent;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.navbar_menuIcon__1q7TU {
  display: none;
}
.navbar_navLogo__T00Pg {
  display: flex;
  align-items: center;
  padding: 1rem;
}
.navbar_navLogo__T00Pg img {
  max-width: 2rem;
  max-height: 2rem;
}
.navbar_navLogo__T00Pg a {
  text-decoration: none;
  color: white;
  font-size: 1.5rem;
}
.navbar_navLogo__T00Pg a:hover {
  color: #f1c40f;
}
.navbar_navLinks__1hp24 {
  color: white;
}
.navbar_navItem__33-KR .navbar_acitveLink__286Li {
  font-size: 1.1rem;
  color: #f1c40f;
  text-align: center;
  position: relative;
}
.navbar_activeNavbar__2QiwC {
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2rem);
  box-shadow: 0 0rem 1rem 0 rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(2rem);
  border: 1px solid rgba(0, 0, 0, 0.18);
}
.navbar_active__1WLIC {
  background-color: transparent;
}
ul.navbar_navMenu__30Xy2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: auto;
  margin-right: 1rem;
  margin-bottom: auto;
  justify-content: space-between;
}
li {
  margin-right: 1rem;
  display: block;
  list-style: none;
}
ul li a {
  padding: 0.5rem 0rem;
  color: white;
  display: inline-block;
  letter-spacing: 2px;
  text-decoration: none;
  transition: ease-out 0.5s;
  position: relative;
  z-index: 1;
  overflow: hidden;
  text-transform: uppercase;
}
.navbar_navbar__2x_1U li > a::before {
  content: "";
  position: absolute;
  top: 95%;
  left: 0;
  background-color: #f1c40f;
  width: 90%;
  height: 100%;
  transform: translateX(-101%);
  transition: ease-out 0.5s;
}
.navbar_navbar__2x_1U li > a:hover:before {
  transform: translateX(0);
  z-index: -1;
}
.navbar_navbar__2x_1U li > a:hover {
  color: white;
}
.navbar_navbar__2x_1U .navbar_notifications__1lEw3 {
  position: absolute;
  padding: 0.15rem;
  top: 8vh;
  right: 0;
  border-radius: 0.25rem;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(2rem);
  box-shadow: 0 0rem 1rem 0 rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(2rem);
  width: 30vw;
}
.navbar_results__3Suql{
  position: relative;
}
@media screen and (max-width: 768px) {
  .navbar_navLogo__T00Pg a {
    font-size: 2rem;
  }
  .navbar_navLogo__T00Pg img {
    max-width: 2.25rem;
    max-height: 2.25rem;
  }
  .navbar_activeNavbar__2QiwC {
    border: none;
  }
  .navbar_menuIcon__1q7TU {
    color: rgba(255, 255, 255, 0.9);
    display: block;
    margin-right: 1rem;
    font-size: 1.8rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1111;
  }
  .navbar_navMenu__30Xy2.navbar_active__1WLIC {
    padding: 10rem 0rem;
    width: 120%;
    background: #242222;
    /* right: -10%; */
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 100;
    justify-content: space-between;
    transform: scale(1);
    flex-direction: column;
  }
  .navbar_navMenu__30Xy2 {
    display: flex;
    flex-direction: column;
    width: 120%;
    position: absolute;
    justify-content: space-between;
    left: -10%;
    top: 0%;
    height: 100vh;
    align-items: center;
    opacity: 0;
    z-index: 0;
    transform: scale(0);
    transition: all 1s ease-out;
  }
}
@media screen and (max-width: 960px) and (min-width: 768px) {
  .navbar_navLinks__1hp24 {
    font-size: 1rem;
  }
  .navbar_navLogo__T00Pg a {
    font-size: 1.25rem;
  }
  .navbar_navLogo__T00Pg img {
    max-width: 1.5rem;
    max-height: 1.5rem;
  }
}

/* .notification {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.3s ease;
} */

.Notification_notification__27_hc:hover {
  transform: scale(1.02);
  transition: all 0.3s ease-in-out;
}

.Notification_notification__27_hc .Notification_title__RHjY7 {
  font-size: 1.1rem;
  font-weight: 400;
}

.Notification_notification__27_hc .Notification_closeIcon__ZgCzL {
  position: absolute;
  right: 3%;
  top: 15%;
  font-size: 1rem;
  color: black;
  cursor: pointer;
}

.Notification_notification__27_hc .Notification_createdTime__30Xoc {
  font-size: 0.75rem;
  text-align: right;
  color: rgba(0, 0, 0, 0.4);
}

.Notification_notification__27_hc .Notification_description__3bXGS {
  color: rgb(43, 43, 43);
  padding: 0.4rem 0;
  font-size: 0.8rem;
  text-overflow: ellipsis;
  line-height: 1.2rem;
}
.services-submenu {
  width: 21rem;
  max-width: 21rem;
  position: absolute;
  top: 100%;
  left: -15%;
  list-style: none;
  text-align: start;
}

.services-submenu li {
  background: black;
  cursor: pointer;
}

.services-submenu li a:hover {
  background: black;
}

.services-submenu.clicked {
  display: none;
}

.submenu-item {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 16px;
}

@media (max-width: 768px) {
  .services-submenu {
    z-index: 100;
  }
}
.dashboard-screen {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
    margin-top: 2rem;
}


.profile-pic {
    position:relative;
    top: 1rem;
    text-align: center;
}
.admin-pic {
    height: 100px;
    width: 100px;
    border-radius: 50px;
}
.admin-info {
    background: rgba(197,190,159,.23137254901960785);
    padding: 1.5rem;
    color: #ffffff;
    border-radius: 20px;
}
.adm-name-conti {
    text-align: center;
}
.admin-name {
    margin-top: 1rem;margin-top: 1rem;
    color: #f1c40f;
    width: 300px;
    height: auto;
    
    width: 300px;
    height: auto;
    
}
.admin-desc {
    display: flex;
    flex-direction: column;

}
.admin-post  {
    padding: 1rem;
    margin-bottom: -0.5rem;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    /* border: 2px solid #f1c40f ;     */
}
.post-refine {
    height: auto;
    display: flex;
    justify-content: center;
    margin-bottom: -0.5rem;
    text-align: center;
    
}
.router-btns {
    background: rgba(197,190,159,.23137254901960785);
    padding: 2rem;
    color: #ffffff;
    border-radius: 20px;
    margin: 2rem;
}
.routing-conti {
    text-align: center;
    padding: 1rem 5rem;
}
.btn-route {
    /* color: #f1c40f; */
    /* color: #ffffff; */
    /* background: transparent; */
    opacity: 1;
    border-width: 0;
    border-radius: 10px;
    padding: 1rem;
    /* background-color: rgba(197,190,200,.23137254901960785); */
    background-color: #24a0ed;
    width: 15rem;
    font-size: 20px;
}
.logout-container {
    display: flex;
    /* justify-content: right; */
    /* margin-left: auto; */
    /* margin-top: 7rem; */
    /* margin-bottom: -5rem; */
    /* margin-top: 5rem; */

}
.top-bar {
    /* background-color: blue; */
    width: 90vw;
    display: flex;
    flex-direction: row;
    margin-top: 6rem;
}

.logout-container button:hover {
    color: white;
}
.back-container {
    display: flex;
    justify-content: left;
    cursor: pointer;
    margin-right: auto;
}
/* extends */

/* layout css */
.maindiv_loginpage {
  margin-top: 4vh;
  display: flex;
  flex-direction: row;
  background: url(/static/media/tsg.ca5bf5ca.jpg) no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
}
.background_overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.container_cf {
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translateX(-50%);
  width: 40%;
  z-index: 2;
}

.wrapper {
  padding: 10px;
}

/* global css */
.frm--create-account {
  width: 100%;

  background-color: #fff;
  border-radius: 0.5rem;
  padding: 1rem 1rem 1rem 1rem;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14),
    0 6px 30px 5px rgba(160, 170, 179, 0.12),
    0 8px 10px -5px rgba(171, 191, 216, 0.4);
}

.frm--create-account label {
  color: #7b5ddd;
  font-family: inherit;
  font-size: 11px;
  text-transform: uppercase;

  font-weight: bold;
  letter-spacing: 0.5px;
  padding: 0 18px;
  margin: 0 0 3px;
  display: block;
}

.frm-group {
  margin: 0 0 15px;
}

.frm-group.inline .frm-group {
  flex: 1 1 100%;
}

.frm-group.inline .frm-group:not(:last-child) {
  margin: 0 15px 15px 0;
}

.frm__title {
  font-size: 1.8rem;
  text-align: center;
  letter-spacing: -1px;
  word-spacing: -1px;
  margin: 0;
  padding: 1em 0 1em;
  color: #613ae2;
}

.frm-info {
  margin: 1em 0;
}

.frm__txt {
  font-family: inherit;
  font-size: 14px;
  text-align: center;
}

.frm__link {
  font-family: inherit;
  font-weight: 600;
  text-decoration: underline;
  color: #b2002d;
}

.frm__btn-primary {
  margin-top: 2rem;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: #fff;
  background-color: #b2002d;
  border: 1px solid;
  cursor: pointer;
}

.frm__btn-primary:hover {
  background-color: #b2002d;
}

.frm__or {
  width: 100%;
  height: 1px;
  position: relative;
  background-color: #f7f7f7;
  margin: 2em 0;
}

.frm__or:before {
  content: "OR";
  text-align: center;
  font-family: inherit;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #ebebeb;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  padding: 0 10px;
}

.text_image_login {
  position: absolute;
  top: 45%;
  margin-left: 5%;

  color: #fff;
}
@media screen and (max-width: 1200px) {
  .container_cf {
    width: 50%;
  }
}
@media screen and (max-width: 992px) {
  .container_cf {
    width: 60%;
  }
}
@media screen and (max-width: 568px) {
  .frm-group.inline {
    display: block;
  }
  .frm-group.inline .frm-group:not(:last-child) {
    margin: 0 0 15px;
  }
  .img_loginpage,
  .text_image_login {
    display: none;
  }
  .frm--create-account {
    padding: 100px 1em 100px 1em;
  }
  .container_cf {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    z-index: 2;
  }
  .frm--create-account {
    padding: 0px 1em 10px 1em;
  }
  .frm--create-account input[type="textarea"] {
    height: 70px;
  }
}

.ajay {
  font-family: sans-serif;
  font-size: 14px;
  position: fixed;
  right: 20px;
  bottom: 10px;
}
.ajay a {
  text-decoration: none;
  color: #333;
}
.ajay a:hover {
  text-decoration: underline;
  color: #000;
}

.form_formContainer__2-A2V {
    padding: 30px;
}

.form_formContainer__2-A2V .form_header__eZBW2 h1 {
    margin: 20px 10px;
    color: #4f9bc7;
}

.form_formContainer__2-A2V .form_header__eZBW2 p {
    text-align: center;
    padding: 10px 0;
}

.form_formContainer__2-A2V input {

}

.form_formContainer__2-A2V .form_footer__1C5Q4 {
    text-align: center;
    padding: 5px;
}


.form_form__32fy4 {
    width : 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.form_form__32fy4 .form_formRow__xlm3r {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form_form__32fy4 .form_formRow__xlm3r div {
    position: relative;
    height: 50px;
    flex-grow: 1;
    margin: 10px;
}

.form_form__32fy4 .form_formRow__xlm3r button {
    padding: 15px 20px;
    width: 100%;
    margin: 10px;
    margin-top: 40px;
    background-color: #5fa8d3;
    color: #fff;
    font-size: 18px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.form_form__32fy4 .form_formRow__xlm3r button:hover {
    background-color: #4f9bc7;
    transition: all 0.3s ease;
}

.form_form__32fy4 input {
    width: 100%;
    height: 100%;
    color: #595f6e;
    padding-top: 20px;
    border: none;
    outline: none;
    font-size: 18px;
}

.form_form__32fy4 label {
    position: absolute;
    bottom: 0;
    left: 0;
    text-transform: initial;
    font-size: 18px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: #5fa8d3;
    border-bottom: 1px solid black;
}

.form_form__32fy4 label::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    transition: transform 0.3s ease;
}

.form_contentName__2ZMCT {
    position: absolute;
    bottom: 5px;
    left: 0;
    transition: all 0.3s ease;
}

.form_form__32fy4 input:focus + .form_labelName__elvzw .form_contentName__2ZMCT,
.form_form__32fy4 input:valid + .form_labelName__elvzw .form_contentName__2ZMCT {
    transform: translateY(-150%);
    font-size: 14px;
    color: #5fa8d3;
}

.form_form__32fy4 input:focus .form_labelName__elvzw::after,
.form_form__32fy4 input:valid + .form_labelName__elvzw:after {
    transform: translateX(0%);
}
html {
  scroll-behavior: smooth;
}

.home_backgroundImage__3kvAz {
  width: 100%;
  height: 100vh;
  background: url(/static/media/iitkgp.8d87f784.jpg);
  background-size: cover;
  position: relative;
}

.home_textOverlay__30ck1 {
  padding: 0rem 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
}
.home_textOverlay__30ck1 .home_kgpSvg__3f9H_ {
  height: 30%;
  margin-right: 1rem;
}
.home_textOverlay__30ck1 img {
  height: 100%;
  width: 100%;
}
.home_container__10kUS {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 90vh;
  position: relative;
  background-color: black;
}
.home_committeesContainer__2kFmo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 18vh;
}

.home_aboutUsSection__jOlLb {
  text-align: center;
  margin: auto;
  padding: 4rem 3rem;
  background-color: #2a2b2f;
  backdrop-filter: blur(1.5rem);
  -webkit-backdrop-filter: blur(1.5rem);
  border-radius: 1rem;
  color: white;
  width: 90%;
  max-width: 1200px;
  z-index: 2;
}
.home_committeesSection__1feq9 {
  max-width: 1200px;
  width: 90%;
  margin: auto;
}

.home_sectionHeading__lLwPL {
  text-align: center;
  font-size: 2.25rem;
  margin-bottom: 2rem;
  letter-spacing: 2px;
  color: #f1c40f;
}

.home_description__3_Vcv {
  letter-spacing: 1.5px;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.7);
  line-height: 125%;
}
.home_societiesSection__3ZHwz {
  position: relative;
  width: 90%;
  max-width: 1200px;

  margin-bottom: 5rem;
  display: flex;
}

/* ReadMore Section */

.home_animationTrigger__222CE {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
  cursor: pointer;
}
.home_moreText__205QV {
  display: inline-block;
  padding: 1rem 2rem;
  border: 2px solid white;
  border-radius: 3rem;
  color: #fff;
  color: white;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 1px;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.25s ease-out;
  text-transform: uppercase;
}
.home_moreText__205QV:hover {
  background-color: white;
  color: #f1c40f;
}
.home_animationTrigger__222CE:hover .home_moreText__205QV {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}
.home_moreDots__1dP7x {
  transform: translateY(-15px);
  transition: all 0.3s ease;
}
.home_animationTrigger__222CE:hover .home_moreDots__1dP7x {
  transform: translateY(-5px);
  transition: all 0.3s ease-out;
}
.home_moreDots__1dP7x .home_dot__2vFsi {
  display: inline-block;
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50%;
  margin: 0.25rem;
  background: white;
}
.home_animationTrigger__222CE:hover .home_moreDots__1dP7x .home_dot__2vFsi {
  animation: home_bounce-3__3dTjQ 0.8s 1 ease;
  animation-fill-mode: forwards;
}
.home_animationTrigger__222CE:hover .home_moreDots__1dP7x .home_dot__2vFsi.home_dot2__2L0vu {
  animation-delay: 0.1s;
}
.home_animationTrigger__222CE:hover .home_moreDots__1dP7x .home_dot__2vFsi.home_dot3__18_NX {
  animation-delay: 0.2s;
}
.home_animationTrigger__222CE:hover .home_moreDots__1dP7x .home_dot__2vFsi.home_dot4__21UzW {
  animation-delay: 0.3s;
}
@keyframes home_bounce-1__2n8sb {
  0%,
  25% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(0, 30px) scale(0.2);
    background: #dcdcdc;
  }
  75%,
  100% {
    transform: translate(0, 0) scale(1);
    opacity: 0;
  }
}
@keyframes home_bounce-2__3bSov {
  0% {
    transform: translate(0, 0) scale(1);
  }
  100% {
    transform: translate(0, 30px) scale(0.2);
    background: #dcdcdc;
  }
}
@keyframes home_bounce-3__3dTjQ {
  0% {
    transform: translate(0, 0) scale(1);
  }
  50% {
    transform: translate(0, 15px);
    background: #dcdcdc;
  }
  70% {
    transform: translate(0, 13px);
  }
  100% {
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  .home_societiesSection__3ZHwz {
    display: flex;
    flex-direction: column;
  }
  .home_societiesSection__3ZHwz .home_readMore__1DaUm {
    margin: auto;
    /* padding: 2rem; */
  }
  .home_readMore__1DaUm .home_moreDots__1dP7x {
    display: none;
  }
  .home_readMore__1DaUm .home_moreText__205QV {
    color: #f1c40f;
    border: 1px solid #f1c40f;
    opacity: 1;
    transform: translateY(0);
  }
  .home_textOverlay__30ck1 {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  .home_textOverlay__30ck1 .home_kgpSvg__3f9H_ {
    width: 100%;
    max-height: 25%;
  }
  .home_textOverlay__30ck1 .home_kgpSvg__3f9H_ img {
    object-fit: contain;
  }
}

.committee-card_card__2Dkdl {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  transition: 0.5s;
  margin: 30px auto;
}

.committee-card_card__2Dkdl .committee-card_box__1y1pR {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.committee-card_card__2Dkdl .committee-card_box__1y1pR:hover {
  transform: translateY(-50px);
}

.committee-card_card__2Dkdl .committee-card_box__1y1pR:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.committee-card_card__2Dkdl .committee-card_box__1y1pR .committee-card_content__2w-GA {
  padding: 20px;
  text-align: center;
}

.committee-card_card__2Dkdl .committee-card_box__1y1pR .committee-card_content__2w-GA h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.committee-card_card__2Dkdl .committee-card_box__1y1pR .committee-card_content__2w-GA h3 {
  position: absolute;
  font-size: 1.8rem;
  font-weight: 500;
  top: 45%;
  left: 10%;
  right: 10%;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

.committee-card_card__2Dkdl .committee-card_box__1y1pR .committee-card_content__2w-GA p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
  transition: 0.5s;
}

.committee-card_card__2Dkdl .committee-card_box__1y1pR .committee-card_content__2w-GA a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: black;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.committee-card_card__2Dkdl .committee-card_box__1y1pR .committee-card_content__2w-GA a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  background: #fff;
  color: #d4aa00;
}
@media (max-width: 768px) {
  .committee-card_card__2Dkdl {
    height: 320px;
  }
}
@media (min-width: 700px) {
  .committee-card_card__2Dkdl {
    min-width: 500px;
  }
}

.committee-card_card__2Dkdl .committee-card_box__1y1pR .committee-card_content__2w-GA img{
  /* height: 20px;
  width: 20px; */
  size: cover;
  height: 450px;
  width: 460px;
  opacity: 0.4;
  z-index: -1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.soc_heading__1LVpo {
  font-size: 2.5rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.soc_full__2tQz3 {
  margin-top: 15vh;
}

.soc_img1__3jA_F {
  display: block;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.soc_cardswrapper__48vpM {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  gap: 2rem;
  width: 90%;
  margin: auto;
}

.soc_card__2YW1_ {
  z-index: 3;
  display: flex;
  flex-direction: column;
  flex-flow: column wrap;
  perspective: 100rem;
  height: 316px;
}

.soc_card__2YW1_:hover .soc_cardfront__FF6JL {
  box-shadow: 0rem 0rem 1rem rgba(255, 255, 255, 0.1);
  transform: rotateY(-180deg);
  visibility: hidden;
  opacity: 0;
}

.soc_card__2YW1_:hover .soc_cardback__qj9Im {
  transform: rotateY(0deg);
  visibility: visible;
  opacity: 1;
}

.soc_card__2YW1_:hover .soc_cardback-content__3DwN1 {
  animation: soc_translate__2sw6S 1s ease-in-out;
}

.soc_cardfront__FF6JL {
  transition: all 500ms ease-in-out;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(51, 51, 51, 0.1), 0 0 1rem rgba(51, 51, 51, 0.1);
  overflow: hidden;
}

.soc_cardback__qj9Im {
  position: absolute;
  height: 100%;
  padding: 2rem;
  background: #eee;
  transform: rotateY(180deg);
  transition: all 500ms ease-in-out;
  border-radius: 1rem;
  box-shadow: 0 0 1rem rgba(51, 51, 51, 0.1), 0 0 1rem rgba(51, 51, 51, 0.1);
  overflow: hidden;
  visibility: hidden;

  opacity: 0;
}

.soc_cardbackcontent__1RJr5 {
  max-height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  height: inherit;
  justify-content: space-between;
  grid-gap: 1rem;
  gap: 1rem;
}

@keyframes soc_translate__2sw6S {
  0% {
    transform: translateX(-1rem);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .soc_cardswrapper__48vpM {
    grid-template-columns: auto;
    width: auto;
    justify-content: center;
  }
  .soc_card__2YW1_ {
    max-width: 600px;
  }
}
@media (max-width: 568px) {
  .soc_card__2YW1_ {
    max-width: 280px;
  }
}

/* new */
.soc_cardfront__FF6JL {
  height: 100%;
}

.soc_a1__3qR1w {
  text-decoration: none;
  color: black;
  letter-spacing: 0.1em;
  padding: 5px 5px;
  position: relative;
}
.soc_a1__3qR1w:hover {
  color: black;
}
.soc_a1__3qR1w:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  background: #f1c40f;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}
.soc_a1__3qR1w:hover:after {
  width: 100px;
  left: 0;
}

.tabs_parentDiv__oeHvR{
  width: 80%;
  max-width: 1200px;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs_tabContainer__hjZ4h {
  display: flex;
  justify-content: center;
  grid-gap:16px;
  gap:16px;
  align-items: center;
  padding: 0 12px;
}
.tabs_tabs__bjy5Z {
  color:#eab308 ;
  font-size: 18px;
  font-weight: bold;
  border: 2px solid #f59e0b4d;
  padding: 10px 24px;
  border-radius: 16px;
  background-color: #111827bd;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.tabs_tabs__bjy5Z:hover {
  border: 2px solid #eab308;
  background-color:#1f2937 ;
}
.tabs_activeTab__1KZWj{
  padding: 10px 24px;
  border-radius: 16px;
  font-size: 18px;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(to right, #f59e0b, #d97706); 
  color: black; 
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
}

.tabs_activeTab__1KZWj:hover{
  background: linear-gradient(to right, #d97706, #b45309);
}

.tabs_smallParentDiv__33aOd{
  display: none;
}

.tabs_smTabContainer__1mChP{
  width:240px;
  display:flex;
  justify-content:space-between;
  align-items:center;
  border:1px solid rgb(185, 157, 43);
  padding:12px 18px;
  border-radius:12px;
  background-color: #0a0b0cbd
}

.tabs_smHeading__RGkxo{
  color:#eab308;
  font-size:18px;
  font-weight:600
}

.tabs_smDropdown__16OMh{
  background-color:rgba(10, 11, 12, 0.74);
  color:white;
  cursor: pointer;
  border:0;
}

.tabs_dropdownMenu__1ChhJ{
  display: flex;
  flex-direction: column;
  z-index: 100;
  border-radius:12px;
}
.tabs_dropdownItems__NIVkO {
  width: 240px;
  padding: 12px 18px;
  background-color: black;
  color: white;
  cursor: pointer;
}

.tabs_dropdownItems__NIVkO:hover {
  background-color: #333;
}


@media  (max-width: 1023px) {
  .tabs_activeTab__1KZWj{
    font-size: 14px;
    padding: 8px 8px;
    border-radius: 12px;
  }
  .tabs_tabs__bjy5Z {
    font-size: 14px;
    padding: 8px 8px;
    border-radius: 12px;
  }
}

@media  (max-width: 768px) {
  .tabs_activeTab__1KZWj{
    font-size: 12px;
    padding: 8px 8px;
    border-radius: 12px;
  }
  .tabs_tabs__bjy5Z {
    font-size: 12px;
    padding: 8px 8px;
    border-radius: 12px;
  }
}
@media  (max-width: 548px) {
  .tabs_tabContainer__hjZ4h{
    display: none;
  }
  .tabs_smallParentDiv__33aOd{
    display: block;
  }
  /* .activeTab{
    font-size: 12px;
    padding: 6px 6px;
    border-radius: 10px;
  }
  .tabs {
    font-size: 12px;
    padding: 6px 6px;
    border-radius: 10px;
  } */
}


/* @media (min-width: 300px) and (max-width: 432px) {
  .tabContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:16px;
  align-items: center;
  }
  .activeTab{
    font-size: 14px;
  }
  .tabs {
    font-size: 14px;
  }
} */


.cardParentDiv{
  display: grid;
  max-width: 1200px;
  margin: 0 auto;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  align-items: center;
  justify-content: center;
  grid-gap: 20px;
  gap: 20px;
  padding:8px 12px;
  box-sizing: border-box;
}

.cardContainer {
  background-color: rgb(14, 15, 18);
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  grid-row-gap: 20px;
  row-gap: 20px;
  border: 2px solid rgb(77, 65, 17);
  border-radius: 12px;
  height: 160px;
  transition: transform 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  overflow: hidden;
}
.cardContainer:hover{
  border-color: rgb(185, 157, 43);
  transform: scale(1.05);

}
.imageCardDiv{
  width:132px;
  flex-shrink: 0;
}
.image{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.cardDiv{
  display:flex;
  flex-direction:column;
  justify-content:center;
  padding-right:12px;
}

.cardHeading{
  font-size: 1.25rem;
  font-weight: 500;
  margin-bottom: 8px;
  max-width: 100%;
  text-align: left;

}
.link {
  text-decoration: none;
  color: white; 
  font-size: 24px;
  transition: color 0.3s ease; 
}

.link:hover {
  color: rgb(185, 157, 43); 
}

.cardDiv2{
  display: flex;
  align-items:center;
  grid-gap:8px;
  gap:8px;
  padding: 8px 0;
}
.btnHandler{
  padding: 0.75rem 1.5rem;
  border: 2px solid white;
  border-radius: 30px;
  background-color: transparent;
  color: white;
  cursor: pointer;
  font-style: italic;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btnHandler:hover {
  background-color: white;
  color: black;
}

@media (max-width: 880px) {

  .cardParentDiv{
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
 
}
@media (max-width: 560px) {
  .cardHeading {
    font-size: 1rem;
  }
  .cardParentDiv{
    display: grid;
    grid-template-columns: repeat(2, minmax(260px, 1fr));
  }
 
}

@media (max-width: 480px) {
  .cardParentDiv{
    display: grid;
    grid-template-columns: repeat(1, minmax(300px, 1fr));
  }
  .cardHeading {
    font-size: 1rem;
  }

  .btnHandler {
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
  }
}




.SkeletonSociety_skeletonCard__l5lsN {
  width: 300px;
  height: 120px;
  background-color: #1d1c1c;
  border-radius: 8px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.SkeletonSociety_skeletonImage__1RNGF {
  width: 80%;
  height: 60%;
  background-color: #383838;
  border-radius: 4px;
  margin-bottom: 8px;
}

.SkeletonSociety_skeletonText__2TXQM {
  width: 60%;
  height: 12px;
  background-color: #3d3d3d;
  border-radius: 4px;
}
@keyframes greet_showTopText__3ymUV {
  0% {
    transform: translate3d(0, 100%, 0);
  }
  40%,
  60% {
    transform: translate3d(0, 50%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
@keyframes greet_showBottomText__2Hrz2 {
  0% {
    transform: translate3d(0, -100%, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
}
.greet_animatedTitle__2LlJd {
  font-family: Roboto, Arial, sans-serif;
  height: 100%;
  width: 100%;
}
.greet_animatedTitle__2LlJd > div {
  height: 50%;
  overflow: hidden;
  width: 100%;
}
.greet_animatedTitle__2LlJd > div div span {
  display: block;
}
.greet_animatedTitle__2LlJd > div.greet_textTop__A9IbC {
  letter-spacing: 2px;
  font-size: 2.75rem;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 0.15rem solid #dab834;
  padding: 0.5rem 0rem;
}
.greet_animatedTitle__2LlJd > div.greet_textTop__A9IbC div {
  animation: greet_showTopText__3ymUV 1s;
  animation-delay: 0.5s;
  animation-fill-mode: forwards;
  transform: translate(0, 100%);
}
.greet_animatedTitle__2LlJd > div.greet_textTop__A9IbC div span:first-child {
  color: white;
}
.greet_animatedTitle__2LlJd > div.greet_textBottom__3jOze {
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 2.5rem;
  color: rgba(255, 255, 255, 1);
  padding: 0.5rem 0rem;
}
.greet_animatedTitle__2LlJd > div.greet_textBottom__3jOze div {
  animation: greet_showBottomText__2Hrz2 0.5s;
  animation-delay: 1.75s;
  animation-fill-mode: forwards;
  transform: translate(0, -100%);
}
@media screen and (max-width: 768px) {
  .greet_animatedTitle__2LlJd > div {
    height: 70%;
  }
}
@media screen and (max-width: 562px) {
  .greet_animatedTitle__2LlJd > div {
    height: 80%;
  }
}

body {
  overflow-x: hidden;
}
.contacts_contactsContainer__161eW {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.contacts_maincontainer__3MIXo {
  /*display: flex;*/
  /*max-height: 120vh;*/
  width: 100%;
  margin-top: 1.5rem;
}

.contacts_postHeading__2PpbA {
  font-size: 2rem;
  text-align: center;
  margin: auto;
  color: #f1c40f;
}
.contacts_multipleCards__ufaYR {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin-top: -1rem;
  margin-bottom: -1rem;
}
.contacts_content__29tti::-webkit-scrollbar {
  display: none;
}
.contacts_multipleCards__ufaYR > div {
  margin: 1.5rem 1rem;
}
.contacts_contactsCircle1__2gzuD {
  top: 5%;
  right: -2%;
  background-color: white;
  background: linear-gradient(45deg, #fc466b, #f1c40f);
  height: 18rem;
  width: 18rem;
  position: absolute;
  border-radius: 10rem;
  z-index: -1;
}
.contacts_contactsCircle2__sY95o {
  bottom: 0%;
  left: 5%;
  background-color: white;
  background: linear-gradient(45deg, #fc466b, #f1c40f);
  height: 18rem;
  width: 18rem;
  position: absolute;
  border-radius: 10rem;
  z-index: -1;
}
.contacts_contactsCircle3__5rNcD {
  top: 15%;
  left: 30%;
  background-color: white;
  background: linear-gradient(45deg, #fc466b, #f1c40f);
  height: 15rem;
  width: 15rem;
  position: absolute;
  border-radius: 10rem;
  z-index: -1;
}
.contacts_contactsCircle4__3TWim {
  bottom: 5%;
  right: 30%;
  background-color: white;
  background: linear-gradient(45deg, #fc466b, #f1c40f);
  height: 10rem;
  width: 10rem;
  position: absolute;
  border-radius: 10rem;
  z-index: -1;
}
@media screen and (max-width: 768px) {
  .contacts_maincontainer__3MIXo {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
}

.dropdownSelector {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: -1.5rem;
  margin-bottom: 2rem;
}

.selector {
  border: none;
  background: #eee;
  color: black;
  font-size: 1.2rem;
  padding: 1rem 2.8rem 1rem 1rem;
  border-radius: 5px;
  background-color: #ddd;
  max-width: 60%;
  margin: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.expandMore {
  color: #000;
  position: absolute;
  top: 30%;
  right: 22%;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .expandMore {
    top: 25%;
  }
}

@media screen and (max-width: 576px) {
  .expandMore {
    top: 18%;
  }
}

@media screen and (max-width: 400px) {
  .selector {
    font-size: 1rem;
  }

  .expandMore {
    top: 15%;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}
.contact-card_container__cYlVf {
  background-color: black;
  width: 300px;
  height: 350px;
  border-radius: 0.5rem;
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  margin: 1rem 1rem;
}
.contact-card_imgbox__FMSWR {
  transition: 0.5s;
  height: 80%;
  overflow: hidden;
}
img,
.contact-card_svgContainer__25K7u {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  object-fit: cover;
}
.contact-card_container__cYlVf:hover .contact-card_imgbox__FMSWR img,
.contact-card_container__cYlVf:hover .contact-card_imgbox__FMSWR .contact-card_svgContainer__25K7u {
  opacity: 0.4;
  transform: scale(1.1);
}

.contact-card_sociallinks__1QXPw {
  height: auto;
  position: absolute;
  top: 60%;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.contact-card_sociallinks__1QXPw li {
  list-style: none;
}
.contact-card_sociallinks__1QXPw li a,
.contact-card_sociallinks__1QXPw li button {
  color: black;
  display: block;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  font-size: 1.75rem;
  border-radius: 50%;
  pointer-events: none;
  cursor: default;
  transform: translateY(140%);
  transition: 0.4s ease;
  opacity: 0;
}
.contact-card_container__cYlVf:hover .contact-card_sociallinks__1QXPw li a,
.contact-card_container__cYlVf:hover .contact-card_sociallinks__1QXPw li button {
  transform: translateY(25%);
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
.contact-card_container__cYlVf .contact-card_sociallinks__1QXPw li a:hover i,
.contact-card_container__cYlVf .contact-card_sociallinks__1QXPw li button:hover i {
  transition: 0.5s ease-in-out;
  color: rgb(0, 156, 246);
  transform: rotateY(360deg);
}
.contact-card_container__cYlVf:hover .contact-card_sociallinks__1QXPw li:nth-child(1) a {
  transition-delay: 0s;
}
.contact-card_container__cYlVf:hover .contact-card_sociallinks__1QXPw li:nth-child(2) button {
  transition-delay: 0.2s;
}
.contact-card_container__cYlVf:hover .contact-card_sociallinks__1QXPw li:nth-child(3) a {
  transition-delay: 0.3s;
}
.contact-card_details__1sRq5 {
  padding: 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2rem);
  box-shadow: 0 1rem 1rem rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
  height: 20%;
  align-items: center;
  text-align: center;
}
.contact-card_details__1sRq5 h2 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}
.contact-card_details__1sRq5 span {
  color: rgb(86, 38, 139);
  font-size: 1rem;
  font-weight: 600;
}
@media screen and (max-width: 552px) {
  .contact-card_container__cYlVf {
    width: 250px;
    height: 300px;
  }
}
.contact-card_svgContainer__25K7u {
  padding: 10px;
}

.previous_contactsContainer__2jG9W {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: white;
  font-size: 20px;
}
h1 {
  color: white;
}
.previous_row1__1p315 {
  width: 150%;
  border: 1px soid black;
  background-color: white;
}
.previous_col1p__KZ4hC {
  width: 50%;
  border: 1px solid black;
  text-align: left;
  border-right: none;
  padding: 0 10% 0 2%;
  font-size: 20px;
}

.previous_col2p__1Nn4Z {
  width: 20em;
  border: 1px solid black;
  text-align: left;
  padding: 0 2% 0 2%;
  font-size: 20px;
}
/* .row1 :hover {
  background-color: whitesmoke;
} */
.previous_contacts_content__1wT_L__3YXGr {
  padding: 2 rem;
  margin-right: 0.05 rem;
  max-height: 90 vh;
  overflow-y: scroll;
  width: 100% !important;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.previous_container__3cKaL {
  background-color: black;
  width: 300px;
  height: 350px;
  border-radius: 0.5rem;
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}
.previous_imgbox__1vYMp {
  transition: 0.5s;
  height: 80%;
  overflow: hidden;
}
img {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  object-fit: cover;
}

.previous_sociallinks__vAZ0V {
  height: auto;
  position: absolute;
  top: 60%;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.previous_sociallinks__vAZ0V li {
  list-style: none;
}
.previous_sociallinks__vAZ0V li a {
  color: black;
  display: block;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  font-size: 1.75rem;
  border-radius: 50%;
  pointer-events: none;
  cursor: default;
  transform: translateY(140%);
  transition: 0.4s ease;
  opacity: 0;
}

.previous_details__2nQ3y {
  padding: 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2rem);
  box-shadow: 0 1rem 1rem rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
  height: 20%;
  align-items: center;
}
.previous_details__2nQ3y h2 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}
.previous_details__2nQ3y span {
  color: rgb(86, 38, 139);
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
}
.previous_cards__3vKtT {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.previous_card1__aACG6 {
  color: white;
  text-align: center;
  font-size: 21px;
  width: 35%;
  box-shadow: 0 0 10px white;
  margin: 3%;
  padding: 2%;
  justify-content: space-evenly;
  border-radius: 10px;
}
.previous_senateCard__37tUB {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  justify-content: center;
  margin-left: 5%;
}
.previous_shead1__1Bart {
  font-size: 35px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 3%;
  align-items: center;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .previous_cards__3vKtT {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .previous_cards__3vKtT > div {
    margin-top: 3rem;
  }
}
@media screen and (max-width: 464px) {
  .previous_card1__aACG6 {
    font-size: 11px;
    margin: 3% 1% 3% 1%;
    width: 48%;
    box-shadow: 0 0 3px white;
  }
  .previous_senateCard__37tUB {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    margin-left: 0%;
  }
  .previous_shead1__1Bart {
    font-size: 24px;
  }
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.container {
  background-color: black;
  border-radius: 0.5rem;
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
}
.imgbox {
  transition: 0.5s;
  height: 80%;
  overflow: hidden;
}
img {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  object-fit: cover;
}

.container:hover .imgbox img {
  opacity: 0.4;
  transform: scale(1.1);
}
.sociallinks {
  height: auto;
  position: absolute;
  top: 60%;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.sociallinks li {
  list-style: none;
}
.sociallinks li a {
  color: black;
  display: block;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  font-size: 1.75rem;
  border-radius: 50%;
  pointer-events: none;
  cursor: default;
  transform: translateY(140%);
  transition: 0.4s ease;
  opacity: 0;
}
.container:hover .sociallinks li a {
  transform: translateY(25%);
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
.container .sociallinks li a:hover i {
  transition: 0.5s ease-in-out;
  color: rgb(86, 38, 139);
  /* transform: rotateY(360deg); */
}
.container:hover .sociallinks li:nth-child(1) a {
  transition-delay: 0s;
}
.container:hover .sociallinks li:nth-child(2) a {
  transition-delay: 0.2s;
}
.container:hover .sociallinks li:nth-child(3) a {
  transition-delay: 0.3s;
}
.details {
  padding: 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2rem);
  box-shadow: 0 1rem 1rem rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
  height: 20%;
  align-items: center;
}
.details h2 {
  font-size: 1.25rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}
.details span {
  color: rgb(86, 38, 139);
  font-size: 1rem;
  font-weight: 600;
}
.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0;
  margin: -1rem;
}

@media screen and (max-width: 768px) {
  .cards {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.events_bgContainer__IlDEJ {
  margin-top: 12vh;
}

.events_mainContainer__intv2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  width: 90%;
  max-width: 1200px;
  padding: 3rem 0;
  color: white;
}
.events_categoryContainer__1xI11 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.events_eventSlider__3STw8 div img {
  object-fit: contain;
}
.events_categoryHeading__1L2az {
  font-size: 2rem;
  color: #f1c40f;
  margin-bottom: 2rem;
}
.events_categoryHeading2__subaJ {
  font-size: 2rem;
  color: #f1c40f;
  margin-bottom: 2rem;
}
.events_categoryHeading1__3HCSt {
  font-size: 2.2rem;
  color: #f1c40f;
  margin-bottom: -2%;
  margin-top: 0%;
  text-align: center;
}
.events_cardsWrapper__UHOig {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
}
/* Slider */
.events_events__3DCEf {
  margin: auto;
}

.events_carousel-caption-bottom__2XnY_ {
  font-size: 15px !important;
  color: black !important;
}
.events_thumbnails__2VNqm img {
  width: 10% !important;
}

@media screen and (max-width: 576px) {
  .events_categoryHeading2__subaJ {
    font-size: 2rem;
    color: #f1c40f;
    margin-bottom: 2rem;
  }
  .events_categoryContainer__1xI11 {
    margin: 2rem 0;
  }
}

.events_highlightsContainer__WuqmN {
  display: flex;
  flex-direction: column;
  background-color: #2a2b2f;
  border-radius: 10px;
  border-color: #f1c40f;
  border-style: solid;
  border-width: 1px;
}

.events_highlightsContainer__WuqmN a {
  color: #f1c40f;
}

.events_highlightsContainer__WuqmN a:hover {
  color: #ffd633;
  text-decoration: underline;
  cursor: pointer;
}

.events_highlightsContainerHeader__1F_gv {
  display: flex;
  justify-content: space-between;
  padding: 20px 30px;
  border-width: 0;
  border-color: #f1c40f;
  border-style: solid;
  border-bottom-width: 1px;
}

.events_highlightsContainerHeader__1F_gv .events_timesIcon__VGl61 {
  font-size: 24px;
  cursor: pointer;
}

.events_highlightsContainerMainHeading__3DPaU {
  display: flex;
  align-items: center;
}

.events_highlightsContainerMainHeading__3DPaU .events_bellIcon__fH-nz {
  font-size: 24px;
  margin-right: 10px;
}

.events_highlightsContainerMainHeading__3DPaU h1 {
  font-size: 28px;
  font-weight: bold;
  margin: 0;
}

.events_highlightsContainerBody__cSKds {
  padding: 2rem 4rem;
}

.events_scheduleTile__3E9QN {
  padding: 1.5rem 0;
  color: #fff;
  align-self: stretch;
}

.events_details__TYiJD {
  width: 100%;
  max-width: 1300px;
}

.events_details__TYiJD > .events_summary__2SzRj {
  padding: 8px;
  padding-left: 2.2rem;
  border: none;
  cursor: pointer;
  list-style-position: inside;
  border-bottom: 2px solid #f1c40f;
  position: relative;
  display: block;
  transition: all 0.3s;
  font-family: var(--font1);
}

.events_details__TYiJD > div {
  padding: 8px;
  border-bottom: 2px solid #f1c40f;
  line-height: 2rem;
  font-family: var(--font2);
  transition: 0.3s all ease;
}

.events_summary__2SzRj:before {
  content: "";
  border-width: 0.4rem;
  border-style: solid;
  border-color: transparent transparent transparent #f1c40f;
  position: absolute;
  top: 1rem;
  left: 1rem;
  transform: rotate(0);
  transform-origin: 0.2rem 50%;
  transition: 0.3s transform ease;
}

.events_details__TYiJD .events_summary__2SzRj::-webkit-details-marker {
  display: none;
}

.events_details__TYiJD[open] > .events_summary__2SzRj:before {
  transform: rotate(90deg);
}

.events_details__TYiJD li {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.events_details__TYiJD .events_liIcon__3Xjrj {
  margin: auto 10px;
}

.events_illuVideoSection__3_HgH {
  display: flex;
  justify-content: center;
  margin: 0px auto;
}

.events_illuVideoSection__3_HgH .events_illuVideo__llV96 {
  min-height: 250px;
}

.events_inductionIntro__2ENPN .events_info__NCYDM {
  padding: 50px;
  border: 1px solid red;
  border-radius: 8px;
  max-width: 350px;
  width: 80%;
  margin: auto 20px;
}

@media (min-width: 700px) {
  .events_illuVideoSection__3_HgH .events_illuVideo__llV96 {
    min-height: 500px;
  }
}

@media screen and (max-width: 768px) {
  .events_inductionIntro__2ENPN .events_info__NCYDM {
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    margin: 20px 20px;
  }
  .events_inductionIntro__2ENPN .events_info__NCYDM p {
    font-size: 20px;
  }
}

.events_inductionIntro__2ENPN {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.events_inductionIntro__2ENPN .events_handbookContainer__1U7Co iframe {
  height: 450px;
}

.events_inductionIntro__2ENPN .events_handbookContainer__1U7Co {
  text-align: center;
}

* {
  box-sizing: border-box;
}
body {
  background: black;
}
/*Font Awesome Fonts*/
@font-face {
  font-family: "FontAwesome";
  font-weight: normal;
  font-display: auto;
  font-style: normal;
  src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2")
    format("woff2");
}
.eventcard_blogCard__3aWQm {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 0.5rem;
  overflow: hidden;
  z-index: 0;
  width: 100%;
}
.eventcard_blogCard__3aWQm a {
  color: inherit;
}
.eventcard_blogCard__3aWQm a:hover {
  color: #e4b601;
}
.eventcard_blogCard__3aWQm:hover .eventcard_photo__2knIh {
  transform: scale(1.3) rotate(3deg);
}
.eventcard_blogCard__3aWQm .eventcard_meta__kywsw {
  position: relative;
  z-index: 0;
  height: 120px;
}
.eventcard_blogCard__3aWQm .eventcard_photo__2knIh {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
}
.eventcard_blogCard__3aWQm .eventcard_details__2Ej7o,
.eventcard_blogCard__3aWQm .eventcard_details__2Ej7o ul {
  margin: auto;
  padding: 0;
  list-style: none;
}
.eventcard_blogCard__3aWQm .eventcard_details__2Ej7o {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100%;
  margin: auto;
  transition: left 0.2s;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px;
  width: 100%;
  font-size: 0.9rem;
}
.eventcard_blogCard__3aWQm .eventcard_details__2Ej7o a {
  text-decoration: none;
}
.eventcard_blogCard__3aWQm .eventcard_details__2Ej7o ul li {
  display: inline-block;
}
.eventcard_blogCard__3aWQm .eventcard_details__2Ej7o .eventcard_date__1Emv-:before {
  font-family: FontAwesome;
  margin-right: 10px;
  content: "\f133";
}
.eventcard_blogCard__3aWQm .eventcard_details__2Ej7o .eventcard_tags__tZmVO ul:before {
  font-family: FontAwesome;
  content: "\f02b";
  margin-right: 10px;
}
.eventcard_blogCard__3aWQm .eventcard_details__2Ej7o .eventcard_tags__tZmVO li {
  margin-right: 2px;
}
.eventcard_blogCard__3aWQm .eventcard_details__2Ej7o .eventcard_tags__tZmVO li:first-child {
  margin-left: -4px;
}
.eventcard_blogCard__3aWQm .eventcard_description__2u9Fq {
  padding: 2rem;
  background: rgb(255, 255, 255);
  position: relative;
  flex-grow: 1;
  z-index: 1;
}
.eventcard_blogCard__3aWQm .eventcard_description__2u9Fq h1,
.eventcard_blogCard__3aWQm .eventcard_description__2u9Fq h2 {
  font-family: Poppins, sans-serif;
}
.eventcard_blogCard__3aWQm .eventcard_description__2u9Fq h1 {
  color: black;
  line-height: 1;
  margin: 0;
  font-size: 1.7rem;
}
.eventcard_blogCard__3aWQm .eventcard_description__2u9Fq h2 {
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #918a8a;
  margin-top: 5px;
}
.eventcard_blogCard__3aWQm .eventcard_description__2u9Fq .eventcard_readMore__2zGV8 {
  text-align: right;
}
.eventcard_blogCard__3aWQm .eventcard_description__2u9Fq .eventcard_readMore__2zGV8 button{
  /* color: #e4b601; */
  position: relative;
  top: 1vw;
  text-decoration: none;
  /* border: 2px solid #e4b601; */
  border-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin: 0 0.5rem;
}
.eventcard_blogCard__3aWQm .eventcard_description__2u9Fq .eventcard_readMore__2zGV8 button:after {
  content: "\f061";
  font-family: FontAwesome;
  margin-left: -10px;
  opacity: 0;
  vertical-align: middle;
  transition: margin 0.3s, opacity 0.3s;
}
.eventcard_blogCard__3aWQm .eventcard_description__2u9Fq .eventcard_readMore__2zGV8 button:hover:after {
  margin-left: 5px;
  opacity: 1;
}
.eventcard_blogCard__3aWQm p {
  position: relative;
  margin: 1rem 0 0;
  color: black;
}
.eventcard_blogCard__3aWQm p:first-of-type {
  margin-top: 1.25rem;
  white-space: pre-wrap;
}
.eventcard_blogCard__3aWQm p:first-of-type:before {
  content: "";
  position: absolute;
  height: 5px;
  background: #f1c40f;
  width: 35px;
  top: -0.75rem;
  border-radius: 3px;
}
.eventcard_blogCard__3aWQm:hover .eventcard_details__2Ej7o {
  left: 0%;
}

.eventcard_btnsContainer__3fyKN {
  display: flex;
  flex-direction: row;
  justify-content: right;
}
.eventcard_disable__2SseD{
  display: none;
}

@media (min-width: 768px) {
  .eventcard_blogCard__3aWQm {
    flex-direction: row;
    width: 100%;
  }
  .eventcard_blogCard__3aWQm .eventcard_meta__kywsw {
    flex-basis: 40%;
    height: auto;
  }
  .eventcard_blogCard__3aWQm .eventcard_description__2u9Fq {
    flex-basis: 60%;
    padding: 50px;
  }
  .eventcard_blogCard__3aWQm .eventcard_description__2u9Fq:before {
    transform: skewX(-3deg);
    content: "";
    background: #fff;
    width: 30px;
    position: absolute;
    left: -10px;
    top: 0;
    bottom: 0;
    z-index: -1;
  }
  .eventcard_blogCard__3aWQm.eventcard_alt__2tlS_ {
    flex-direction: row-reverse;
  }
  .eventcard_blogCard__3aWQm.eventcard_alt__2tlS_ .eventcard_description__2u9Fq:before {
    left: inherit;
    right: -10px;
    transform: skew(3deg);
  }
  .eventcard_blogCard__3aWQm .eventcard_details__2Ej7o {
    padding: 30px 45px;
  }
}

.popup {
    position: fixed;
    display: flex;
    z-index: 100;
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    /* color: white; */
    height: 100%;
    width: 100%;
    /* border: 3px solid white; */
    background-color: rgba(0, 0, 0, 0.5);
    /* background-color: #2a2b2f; */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.popup-body {
    border: 0.2rem solid #9a988f;
    border-radius: 2rem;
    padding: 1rem 1rem;
    background-color: #2a2b2f !important;
    margin: 3% auto;
    height: auto;
    width: 70%;
}

.popup-body hr {
    color: #f1c40f;
}

.popup-heading {
    color: #f1c40f;
    display: flex;
    padding: auto auto;
    margin-top: 1rem;
    text-align: center;
}

.props-title {
    margin: auto auto;
    /* font-size: 1.5rem; */
    font-size: 2rem;
}

.popup-closeButton {
    margin: 1% 2% 0 0;
    color: #d11a2a;
    padding: 0 0.5rem;
}

.popup-closeButton:hover {
    /* border: 1px solid #f1c40f; */
    color: #fa061a;
    cursor: pointer;
}

.popup-content {
    display: flex;
    /* align-items: center; */
    overflow-y: scroll;
    height: 85%;
    width: 100%;
    color: #eeeeee;
    font-size: 1rem;
    padding: 1rem 2rem;
}

.popup-content-image {
    display: flex;
    height: 80%;
    width: 80%;
    /* border-right: 4px solid #f1c40f; */
    border-radius: 0.2rem;
}

.text {
    display: flex;
    flex-direction: column;
}

.content-date {
    color: #f1c40f;
    font-weight: bold;
    font-size: 1.2rem;
}

.content-description {
    white-space: pre-wrap;

}

#resultsForm {
    display: flex;
    flex-direction: column;
    display: flex;
    overflow-y: scroll;
    height: 85%;
    width: 100%;
    color: #eeeeee;
    font-size: 1rem;
    padding: 1rem 2rem;
}

#formConti {
    padding: 0 5rem;
    margin: 2rem 0;
}

.save-btn {
    margin: 1rem 0;
}

#subHeading {
    text-align: center;
    color: #f1c40f;
    font-weight: bold;
    font-size: 1.2rem;
}

.linkDisplay {
    display: block;
    font-size: 1.2rem;
}

.noDisplay {
    display: none;
}


@media (max-width: 540px) {
    .popup-body{
        width: 100%;
    }
    .popup-content{
        height: 90%;
        padding: 0.5rem;
    }
    .props-title {
        font-size: 3rem;
    }
    .popup-content>h4{
        font-size: 2rem;
    }
}
.skeleton {
    /* background-color: #ddd; */
    
    animation: skeleton-loading 1s ease-in-out infinite alternate;
    margin: 10px 0;
    border-radius: 10px;
}

@keyframes skeleton-loading {
    0% {
        background-color: #15202B;
        opacity: 0.9;
        transform: translateY(6px) scale(0.98);
    }
    100% {
        background-color: #15202B;
        opacity: 1;
        transform: translateY(0) scale(1);
    }
}

.skeleton.text {
    width: 100%;
    height: 12px;
}

.skeleton.title {
    width: 50%;
    height: 20px;
    margin-bottom: 15px;
}

.skeleton.avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
}

.skeleton.thumbnail {
    width: 75rem;
    height: 17rem;
    margin: 2rem 0;
}

@media screen and (max-width: 768px){
       .skeleton.thumbnail {
        width: 100%;
        height: 17rem;
        margin: 2rem 0;
    }
     }
.mobile_view {
    display: none;
  }
  .laptop_view {
    display: block;
  }
  .page4_maindiv {
    margin-top: 4%;
  }
  .button_div_page4 {
    position: relative;
    margin-top: 2%;
    margin-left: 43%;
  }
  .getin {
    margin-left: 150px;
  }
  .heading_1 {
    margin-left: 5%;
    font-size: 45px;
  }
  .heading_1span {
    position: relative;
  }
  .heading_1span:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: #f26c4f;
    transform: rotate(-5deg);
  }
  .heading_2 {
    margin-left: 22.5%;
    font-size: 45px;
  }
  .learn_heading {
    text-indent: 20px;
    color: #f26c4f;
  }
  .grid_imgs {
    border-radius: 15px;
    margin-bottom: 50px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .layer {
    background: transparent;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s;
  }
  
  .layer:hover {
    background: rgba(5, 5, 5, 0.5);
  }
  
  .layer h3 {
    width: 100%;
    font-weight: 500;
    font-size: 32px;
    position: absolute;
    align-items: center;
    text-align: center;
    bottom: 0;
    width: 100%;
    left: 50%;
    transform: translate(-50%);
    transition: 0.5s;
    color: #fff;
  }
  
  .layer:hover h3 {
    bottom: 49%;
    opacity: 1;
  }
  .grid_imgs:hover .myimg {
    transform: rotateZ(10deg);
    transition: 0.5s;
  }
  .myimg {
    width: 100%;
    
  }
  .row1{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
  .btn {
    position: relative;
    display: inline-block;
    padding: 0.5em 2em;
    cursor: pointer;
    overflow: hidden;
  }
  .btn:before,
.btn:after {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #fff;
}
.btn:before {
  top: 0;
}
.btn:after {
  bottom: 0;
}
.btn:hover > * > *:before,
.btn:hover > * > *:after {
  transform: translate3d(0, 0, 0);
}
.btn:hover > * > * > *:before,
.btn:hover > * > * > *:after {
  transform: translate3d(0, 0, 0);
}
.btn > *:before,
.btn > *:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 2px;
  background-color: #fff;
}
.btn > *:before {
  left: 0;
}
.btn > *:after {
  right: 0;
}
.btn > * > *:before,
.btn > * > *:after {
  content: "";
  position: absolute;
  left: 0;
  z-index: 9;
  height: 2px;
  width: 100%;
  background-color: #f1c40f;
}
.btn > * > *:before {
  top: 0;
  transform: translate3d(-105%, 0, 0);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn > * > *:after {
  bottom: 0;
  transform: translate3d(105%, 0, 0);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn > * > * > *:before,
.btn > * > * > *:after {
  content: "";
  position: absolute;
  top: 0;
  z-index: 9;
  height: 100%;
  width: 2px;
  background-color: #f1c40f;
}
.btn > * > * > *:before {
  left: 0;
  transform: translate3d(0, 105%, 0);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.btn > * > * > *:after {
  right: 0;
  transform: translate3d(0, -105%, 0);
  transition: transform 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}
.border_box {
  font-weight: 600;
  font-size: 40px;
  color: #fff;
}
.heading_box{
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.maindiv_gallery{
  margin-top: 6%;
  color: #fff;
}

  @media screen and (max-width: 768px) {
    .layer h3 {
      font-size: 15px;
      left: 65%;
    }
    .grid_imgs {
      height: 80px;
      width: 80px;
    }
    .heading_2 {
      text-align: end !important;
      padding-right: 22px;
      font-size: 25px;
      margin-left: 10%;
    }
    .heading_1 {
      margin-left: 20px;
      font-size: 25px;
    }
    .heading_1span {
      font-size: 15px;
    }
    .getin {
      margin-left: 20px;
    }
    .img-grid_row {
      position: relative;
      margin-top: 10%;
    }
    .layer h3 {
      font-size: 12px;
    }
    .page4_maindiv {
      position: relative;
      margin-top: 15%;
    }
    .button_div_page4 {
      position: relative;
      margin-top: -5%;
      margin-left: 49%;
    }
    .img-grid_row {
      position: relative;
      margin-top: 10%;
    }
    .row1{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    .maindiv_gallery{
      margin-top: 20%;
      color: #fff;
    }
    .border_box {
      font-weight: 400;
      font-size: 30px;
      color: #fff;
    }
  }
  
.tech_videoContainer__3dJ7p {
    width: 100%;
}

.tech_techMovieVideo__2lOii {
    width: 100%;
    min-height: 400px;
}

.committees_mainContainer__19KAK {
  margin-top: 11vh;
}

h1 {
  text-align: center;
  font-size: 2.25rem;
  margin-bottom: 2rem;
  letter-spacing: 2px;
  color: #f1c40f;
}

h1.committees_committeeHeading__m-BMK {
  color: white;
}

section {
  width: 90%;
  max-width: 1200px;
  margin: 3rem auto;
}

section h2 {
  text-align: center;
  /* font-size: 2rem;  PAHLE CLASSNAME DEFINE KAR LE, TAB CSS DEFINE KAR...BAHUT JAGAH CHANGE HO JA RHI HAI */
  /* margin-bottom: 2rem;
  letter-spacing: 2px; */
  /* color: #f1c40f; */
}

.committees_cmtCard1__3hI6g {
  display: flex;
  flex-wrap: wrap;
}

.committees_leftCard__4QX0p {
  width: 50%;
}

.committees_leftCard2__3ob5X {
  width: 50%;
  color: rgb(194, 194, 194);
  padding: 5% 5% 5% 5%;
  /* text-align: justify; */
  letter-spacing: 1.5px;
  font-size: 18px;
}

.committees_leftCard__4QX0p .committees_img__2L3gG {
  width: 430px;
  padding: 0 2% 0 8%;
}

.committees_cardImg__3JDQb {
  align-content: center;
  width: 480px;
  padding: 0 2% 0 8%;
}

.committees_cardImg2__2XhAW {
  align-content: center;
  width: 600px;
  padding: 1% 1% 0 2%;
}

.committees_cardImg3__1O-NS {
  align-content: center;
  width: 580px;
  height: auto;
  padding: 1% 4% 0 2%;
}

.committees_rightCard__1E3BA {
  width: 50%;
  color: rgb(194, 194, 194);
  padding: 0% 5% 5% 5%;
  /* text-align: justify; */
  letter-spacing: 1.5px;
  font-size: 18px;
}

.committees_centerCard__2nL6x {
  letter-spacing: 1.5px;
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  line-height: 125%;
  padding: 0rem 2rem;
}

.committees_btn__16CzN {
  background-color: rgb(235, 235, 235);
  color: black;
  margin: 2px 6px;
  padding: 5px 6px;
  border: 1px solid #f1c40f;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;
  font-size: 0.8rem;
  display: inline-block;
}

.committees_btn__16CzN:hover {
  background-color: #f1c40f;
  color: white;
  transition: all 0.2s ease-in-out;
}

.committees_hrLine__3CTq5 {
  width: 75%;
  color: grey;
}

.committees_head2__2MlgK {
  color: #f1c40f;
  letter-spacing: 2px;
  margin: 3% 0;
  font-size: 1.8rem;
}

section .committees_description__5QpXy {
  letter-spacing: 1.5px;
  font-size: 1.5rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 125%;
  padding: 0rem 2rem;
}

.committees_imgContainer__6tS1f {
  height: 100%;
  width: 90%;
  border-radius: 1rem;
  margin: 0rem auto 2rem;
}

.committees_imgContainer__6tS1f img {
  /* max-height: 100vh; */
  border-radius: 1.25rem;
  object-fit: cover;
  object-position: top;
  width: 500px;
}

.committees_imgContainer3__3bYLv img {
  /* max-height: 100vh; */
  border-radius: 1.25rem;
  object-fit: cover;
  object-position: top;
  width: 700px;
}

.committees_img2Container__2wIEA img {
  max-height: auto;
  border-radius: 1.25rem;
  object-fit: cover;
  object-position: top;
  width: 500px;
}

/* Welcome Section */
.committees_welcomeSection__cPq-w {
  position: relative;
  height: 60vh;
}

.committees_backgroundImage__2iJ-j {
  background-image: url(/static/media/tsg.ca5bf5ca.jpg);
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(0.1rem);
}

.committees_welcomeContent__1Beff {
  position: absolute;
  top: 0px;
  z-index: 3;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.3);
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.committees_welcomeContent__1Beff h1 {
  font-size: 3rem;
  font-weight: bolder;
}

@media screen and (max-width: 900px) {
  .committees_img2Container__2wIEA img {
    max-height: auto;
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 450px;
  }

  .committees_imgContainer__6tS1f img {
    /* max-height: 100vh; */
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 450px;
  }

  .committees_cmtCard1__3hI6g {
    display: flex;
    flex-wrap: wrap;
  }

  .committees_leftCard__4QX0p {
    width: 50%;
  }

  .committees_leftCard2__3ob5X {
    width: 50%;
    color: rgb(194, 194, 194);
    padding: 5% 5% 5% 5%;
    /* text-align: justify; */
    letter-spacing: 1.5px;
    font-size: 18px;
  }

  .committees_leftCard__4QX0p .committees_img__2L3gG {
    width: 430px;
    padding: 0 2% 0 8%;
  }

  .committees_cardImg__3JDQb {
    align-content: center;
    width: 350px;
    padding: 0 2% 0 8%;
  }

  .committees_cardImg2__2XhAW {
    align-content: center;
    width: 350px;
    padding: 1% 1% 0 2%;
  }

  .committees_rightCard__1E3BA {
    width: 50%;
    color: rgb(194, 194, 194);
    padding: 0% 2% 2% 2%;
    /* text-align: justify; */
    letter-spacing: 1.5px;
    font-size: 14px;
  }

  .committees_centerCard__2nL6x {
    letter-spacing: 1.5px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    line-height: 125%;
    padding: 0rem 2rem;
  }


  .committees_btn__16CzN {
    background-color: rgb(235, 235, 235);
    color: black !important;
    margin: 1px;
    padding: 6px 5px 5px 5px;
    border: none;
    border-radius: 3px;
  }

  .committees_btn__16CzN:hover {
    background-color: rgb(255, 46, 46);
    transition: 30ms;
    color: white !important;
    /* transform: scale(1.1); */
  }

  .committees_hrLine__3CTq5 {
    width: 75%;
    color: grey;
  }

  .committees_head2__2MlgK {
    color: #f1c40f;
    letter-spacing: 2px;
    margin: 3% 0 -1% 0;
  }
}

@media screen and (max-width: 464px) {
  .committees_img2Container__2wIEA img {
    max-height: auto;
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 280px;
  }

  .committees_imgContainer__6tS1f img {
    /* max-height: 100vh; */
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 280px;
  }

  .committees_imgContainer3__3bYLv img {
    /* max-height: 100vh; */
    border-radius: 1.25rem;
    object-fit: cover;
    object-position: top;
    width: 390px;
  }

  .committees_cmtCard1__3hI6g {
    display: flex;
    flex-wrap: wrap;
  }

  .committees_leftCard__4QX0p {
    width: 90%;
  }

  .committees_leftCard2__3ob5X {
    width: 90%;
    color: rgb(194, 194, 194);
    padding: 5% 5% 5% 5%;
    /* text-align: justify; */
    letter-spacing: 1.5px;
    font-size: 18px;
  }

  .committees_leftCard__4QX0p .committees_img__2L3gG {
    width: 430px;
    padding: 0 2% 0 8%;
  }

  .committees_cardImg__3JDQb {
    align-content: center;
    width: 350px;
    padding: 0 2% 0 8%;
  }

  .committees_cardImg2__2XhAW {
    align-content: center;
    width: 350px;
    padding: 0 1% 0 2%;
  }

  .committees_cardImg3__1O-NS {
    align-content: center;
    width: 330px;
    height: auto;
    padding: 1% 4% 0 2%;
  }

  .committees_rightCard__1E3BA {
    width: 97%;
    color: rgb(194, 194, 194);
    padding: 5% 2% 5% 5%;
    /* text-align: justify; */
    letter-spacing: 1.5px;
    font-size: 14px;
  }

  .committees_btn__16CzN {
    background-color: rgb(235, 235, 235);
    color: black !important;
    margin: 1px;
    padding: 6px 5px 5px 5px;
    border: none;
    border-radius: 3px;
  }

  .committees_btn__16CzN:hover {
    background-color: rgb(255, 46, 46);
    transition: 30ms;
    color: white !important;
    /* transform: scale(1.1); */
  }

  .committees_hrLine__3CTq5 {
    width: 75%;
    color: grey;
  }

  .committees_head2__2MlgK {
    color: #f1c40f;
    letter-spacing: 2px;
    margin: 5% 0 -4% 0;
  }
}

.socult_videoContainer__3kWyF {
    width: 100%;
}

.socult_illuVideo__dV0Tt {
    width: 100%;
    min-height: 400px;
}

.elections_electionHeaderImg__O4omm {
  background-image: url(/static/media/tsg_new.d4756a55.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 30rem;
}

.elections_electionsContainer__3kzpv {
  margin-top: 5vh;
  min-height: 80vh;
  font-family: "Lato";
}

@media screen and (min-width: 720px) {
  .elections_electionsContainer__3kzpv {
    margin-top: 10vh;
    min-height: 80vh;
    font-family: "Lato";
  }
}

.elections_electionsContainer__3kzpv p {
  letter-spacing: 1.5px;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 125%;
  font-family: "Lato";
  margin: 2rem 0rem;
}

.elections_electionBody__rNOtL {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 2rem; 
}

@media screen and (min-width: 720px) {
  .elections_electionBody__rNOtL {
    color: rgba(255, 255, 255, 0.8);
    margin: 2rem 15rem 0; 
  }
}

.elections_electionBody__rNOtL > h5 {
  color: #f1c40f;
  font-weight: 300;
}
/* notices */
.elections_electionBody__rNOtL .elections_notice__17Hpg {
  border: 1px solid;
  border-color: #f1c40f;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 1rem 2rem;
  width: 90%;
  margin: 1rem 0rem;
}
.elections_notices__1nokn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.elections_electionBody__rNOtL button {
  font-size: 1rem;
  border: 1px solid;
  border-color: #f1c40f;
  color: #fff;
  background-image: linear-gradient(45deg, #f1c40f 50%, transparent 50%);
  background-position: 100%;
  background-size: 400%;
  transition: background 200ms ease-in-out;
}
.elections_electionBody__rNOtL button a {
  color: inherit;
}
.elections_electionBody__rNOtL button:hover {
  color: #000;
  background-position: 0;
}
.elections_buttonGroup__3RoUW {
  display: inline-block;
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}
/* Elections Candidates*/
.elections_postSelector__3fHxS {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
select.elections_selectButton__2kVP4 {
  font-size: 1.25rem;
  border: none;
  background: #eee;
  color: black;
  padding: 1rem;
  border-radius: 5px;
  background-color: #ddd;
  max-width: 60%;
  margin: auto;
}
.elections_candidateContainer__2yD1Y {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

.electionCard_container__2raPX {
  background-color: black;
  width: 300px;
  height: 350px;
  border-radius: 0.5rem;
  box-shadow: 0px 0.5rem 1rem rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  margin: 1rem 1rem;
}
.electionCard_imgbox__E_GLF {
  transition: 0.5s;
  height: 80%;
  overflow: hidden;
}
img,
.electionCard_svgContainer__1zh5Z {
  width: 100%;
  height: 100%;
  transition: all 0.5s;
  object-fit: cover;
}

.electionCard_container__2raPX:hover .electionCard_imgbox__E_GLF img,
.electionCard_container__2raPX:hover .electionCard_imgbox__E_GLF .electionCard_svgContainer__1zh5Z {
  opacity: 0.4;
  transform: scale(1.1);
}
.electionCard_sociallinks__3JKFm {
  height: auto;
  position: absolute;
  top: 60%;
  display: flex;
  width: 100%;
  justify-content: space-around;
}
.electionCard_sociallinks__3JKFm li {
  list-style: none;
}
.electionCard_sociallinks__3JKFm li a {
  color: black;
  display: block;
  background-color: #fff;
  padding: 0.5rem 0.75rem;
  font-size: 1.75rem;
  border-radius: 50%;
  pointer-events: none;
  cursor: default;
  transform: translateY(140%);
  transition: 0.4s ease;
  opacity: 0;
}
.electionCard_container__2raPX:hover .electionCard_sociallinks__3JKFm li a {
  transform: translateY(25%);
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}
.electionCard_container__2raPX .electionCard_sociallinks__3JKFm li a:hover i {
  transition: 0.5s ease-in-out;
  color: rgb(86, 38, 139);
  /* transform: rotateY(360deg); */
}
.electionCard_container__2raPX:hover .electionCard_sociallinks__3JKFm li:nth-child(1) a {
  transition-delay: 0s;
}

.electionCard_details__3OTpG {
  padding: 0rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* background-color: white; */
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(2rem);
  box-shadow: 0 1rem 1rem rgba(255, 255, 255, 0.8);
  -webkit-backdrop-filter: blur(2rem);
  height: 20%;
  align-items: center;
  text-align: center;
}
.electionCard_details__3OTpG h2 {
  font-size: 1.5rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}
.electionCard_details__3OTpG span {
  color: rgb(86, 38, 139);
  font-size: 1.25rem;
  font-weight: 600;
}

@media screen and (max-width: 552px) {
  .electionCard_container__2raPX {
    width: 250px;
    height: 300px;
  }
}

.electionCard_svgContainer__1zh5Z {
  padding: 10px;
}

h1
{
    font-size: '38px';
    padding : '20px';
    color: 'blue';
    text-align:center;
}
.btn:before,
.btn:after {
  content: "";
  position: absolute;
  left: 0;
  height: 2px;
  width: 100%;
  background: transparent;
}
.text-center {
  display: none;
}
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  background-color: black;
}
.certif-container {
  display: flex;
  flex-direction: column;
  margin-top: 11vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.admin-title {
  width: 100%;
  text-align: center;
  font-size: 2.25rem;
  margin-bottom: 1.5rem;
  color: #f1c40f;
}

.custom-file {
  width: 80vw;
}
.custom-file button {
  background-color: #04aa04;
  color: green;
}
.custom-file-input {
  display: flex;
  height: 5vh;
  width: 50%;
  font-size: 1.1rem;
  justify-content: center;
  background-color: #f0f8ff21;
  border: 2px solid #34353521;
  color: whitesmoke;
  border-radius: 0.5rem;
  margin: auto auto;
  padding-top: 0.25rem;
  margin-top: 2rem;
}
.custom-file-input:hover {
  cursor: pointer;
}
.custom-file-label {
  width: 50%;
}
.progress {
  background-color: #f0f8ff21;
  border-radius: 0.5rem;
  width: 50%;
  margin: auto auto;
  color: white;
}
.progress-bar {
  background-color: #04aa04;
  border-radius: 0.5rem;
}
.upload-button {
  margin-top: 2rem;
  width: 100%;
  height: 5vh;
  font-size: 1.2rem;
  border-radius: 0.5rem;
  background-color: #008aff;
}
.upload-button:hover {
  cursor: pointer;
}
.fileuploadbutton {
  height: 20vh;
  width: 80%;
  margin: auto auto;
}
.div-shrink {
  width: 80%;
}
@font-face {
    font-family: "FontAwesome";
    font-weight: normal;
    font-display: auto;
    font-style: normal;
    src: url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/fonts/fontawesome-webfont.woff2")
    format("woff2");
}
.modal {
    position : fixed; 
    left : 0; 
    top : 0; 
    right : 0; 
    bottom : 0;
    margin: 0;
    padding: 20% 0; 
    background-image: radial-gradient(circle at center, white, #888);
    /* background-color : rgba(0, 0, 0, 0.5);  */
    display : flex; 
    align-items : center; 
    justify-content : center;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    pointer-events: none;
}

.modal.show {
    opacity: 1;
    pointer-events: visible;
}

.modal.hide {
    display: none;
}

.modal-content { 
    width : 30rem; 
    background-color: #ffffff;
    transform: translateY(-200px);
    transition: all 0.3s ease-in-out;
    margin-top: 0rem;
    margin-bottom: -15rem;
} 

.modal.show .modal-content{
    transform: translateY(0);
}

.modal-header, .modal-footer {
    padding : 10px; 
} 
.modal-title { 
    margin : 0; 
    color: #000;
    text-align: center;
    line-height: 1;
    font-family: Poppins, sans-serif;
    font-size: 1.7rem;
} 
.modal-body { 
    padding : 10px; 
    border-top : 1px solid #eeeeee;
    border-bottom : 1px solid #eeeeee; 
}
.modal-date {
    font-size: 1rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #918a8a;
    margin-top: 5px;
    font-family: Poppins, sans-serif;
}
.poster-img {
    /* max-width: 100%; */
    /* max-height: 100%; */
    /* display: block; */
}


.update-div {
    margin-top: 5rem;
}
.list-row-btn{
    margin: 0 0.5rem;
    padding: 0 0.5rem;
}
.btns-conti{
    text-align: center;
    margin: 0 -2rem;
}
body {
  margin: auto !important;
}

.nominations_electionHeaderImg__2Y5Ta {
  background-image: url(/static/media/tsg_new.d4756a55.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 25rem;
}

.nominations_electionsContainer__17mKL {
  display: block;
  margin: 7vh auto 0;
  min-height: 80vh;
  font-family: "Lato";
}

.nominations_electionsContainer__17mKL p {
  letter-spacing: 1.5px;
  font-size: 1.25rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 125%;
  font-family: "Lato";
  margin: 2rem 0rem;
  justify-content: center !important;
}

.nominations_electionBody__1QBVg {
  color: rgba(255, 255, 255, 0.8);
  display: block;
  margin: 2rem auto 0;
  text-align: center;
}

.nominations_electionBody__1QBVg>h5 {
  color: #f1c40f;
  font-weight: 300;
}

/* notices */
.nominations_electionBody__1QBVg .nominations_notice__3t0oG {
  /* border: 1px solid; */
  /* border-color: #f1c40f; */
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 1rem 2rem;
  width: 90%;
  margin: 1rem auto;
}

.nominations_notices__DJPQA {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.nominations_electionBody__1QBVg button {
  font-size: 1rem;
  border: 1px solid;
  border-color: #f1c40f;
  color: #fff;
  background-image: linear-gradient(45deg, #f1c40f 50%, transparent 50%);
  width: 75%;
  margin-left: 10px;
  margin-right: 10px;
  background-position: 100%;
  background-size: 400%;
  transition: background 200ms ease-in-out;
}

.nominations_btn_interiit__S6oht {
  background-image: linear-gradient(45deg, #f1c40f 50%, transparent 50%);
  background-position: 100%;
  background-color: #f1c40f !important;
}

.nominations_tab__3S7EE .nominations_active__1n_t1 {
  background-color: #F1C40F;
}

.nominations_nomination-btn__GKqPO {
  display: flex !important;
  flex-wrap: wrap !important;
  margin: 1rem !important;
  width: auto;
}

.nominations_electionBody__1QBVg button a {
  color: inherit;
}

.nominations_electionBody__1QBVg button:hover {
  color: #000;
  background-position: 0;
}

.nominations_buttonGroup__2Ph7m {
  display: inline-block;
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}

/* Elections Candidates*/
.nominations_postSelector__3OmNP {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

select.nominations_selectButton__3_RZp {
  font-size: 1.25rem;
  border: none;
  background: #eee;
  color: black;
  padding: 1rem;
  border-radius: 5px;
  background-color: #ddd;
  max-width: 60%;
  margin: auto;
}

.nominations_candidateContainer__3OQJg {
  margin-top: 2rem;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.nominations_googleForm__12aDM {
  width: 100%;
  height: 100vh;
}

.nominations_loadingStateLabel__2ZR2Z {
  color: gray;
  margin: 2rem 1rem !important;
}
.top-heading {
    text-align: center;
}

.tab .activeTab {
    background-color: #F1C40F;
}

.btn_interiit #activeTab {
    background-image: linear-gradient(45deg, #f1c40f 50%, transparent 50%);
    background-position: 100%;
    background-color: #f1c40f !important;
}

.sport-cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 90vh;
    position: relative;
    /* background-color: pink; */
}
.each-sport {
    max-width: 1200px;
    width: 90%;
    margin: auto;
}
.sports-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    /* margin-bottom: 18vh;  */
    
}

.sports-card {
    background-color: 
    #464545E6;
    border-radius: 25px;
    width: 15rem;
    height: 12rem;
    padding: 20px;
    margin: 2rem 0.75rem;
    /* box-shadow: 0px 15px 50px -4px rgba(30,30,60,0.25); */
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
    transition: 0.5s;
    position:relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.sports-card:hover {
    /* margin-top: 92px; */
    /* margin-bottom: -1rem; */
    cursor: pointer;
    box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
    /* transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1); */
    transform: scale(0.9) ;
}
.sports-card:active{
    transform: scale(1.2) ;
}
.icon-div {
    text-align: center;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
}
.sport-img {
    /* text-align: center; */
    /* margin: 0 auto; */
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    display: block;
    padding: auto;
    height: 100px;
    width: 100px;
    /* color: #ffffff; */
    filter: invert(100%);
}
.sport-name {
    color: 
    #F1C40F;
    padding: 1rem 1rem;
    /* margin: 2rem 0; */
}
.live-status {
    background-color: rgba(204, 0, 0, 0.9);
    /* color: rgba(204, 0, 0, 0.9); */
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    display:none;
}
@media (max-width: 540px) {
    .sports-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
    }
    .sports-card {
        background-color: 
        #464545E6;
        border-radius: 25px;
        width: 15rem;
        height: 17rem;
        padding: 20px;
        margin: 2rem 0.75rem;
        /* box-shadow: 0px 15px 50px -4px rgba(30,30,60,0.25); */
        box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
        inset -5px -5px 15px rgba(255, 255, 255, 0.1),
        5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
        transition: 0.5s;
        position:relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .icon-div {
        justify-content: center;
    }
}
.popup {
    position: fixed;
    display: flex;
    z-index: 100;
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    /* color: white; */
    height: 100%;
    width: 100%;
    /* border: 3px solid white; */
    background-color: rgba(0, 0, 0, 0.5);
    /* background-color: #2a2b2f; */
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.popup-body {
    border: 0.2rem solid #9a988f;
    border-radius: 2rem;
    padding: 1rem 1rem;
    background-color: #2a2b2f !important;
    margin: 3% auto;
    height: auto;
    width: 100%;
}

.popup-body hr {
    color: #f1c40f;
}

.popup-heading {
    color: #f1c40f;
    display: flex;
    padding: auto auto;
    margin-top: 1rem;
    text-align: center;
}

.props-title {
    margin: auto auto;
    /* font-size: 1.5rem; */
    font-size: 2rem;
}

.popup-closeButton {
    margin: 1% 2% 0 0;
    color: #d11a2a;
    padding: 0 0.5rem;
}

.popup-closeButton:hover {
    /* border: 1px solid #f1c40f; */
    color: #fa061a;
    cursor: pointer;
}

.popup-content {
    display: flex;
    /* align-items: center; */
    overflow-y: scroll;
    height: 85%;
    width: 100%;
    color: #eeeeee;
    font-size: 1rem;
    padding: 1rem 2rem;
}

.popup-content>table th{
    text-transform: capitalize;
    background-color: #f1c40f;
    font-size: 1rem;
    text-align: center;
    border: 2.5px solid #d9dadb;
    font-weight: 700;
    color: #ffffff;

}

.popup-content>table td{
    background-color: #2a2b2f;
    font-size: 0.9rem;
    text-align: center;
    align-items: center;
    border: 0.8px solid #d9dadb;
    color: #fff;
}
iframe {
    display: block;
    margin: 1rem auto;
}
.text {
    display: flex;
    flex-direction: column;
}

.content-date {
    color: #f1c40f;
    font-weight: bold;
    font-size: 1.2rem;
}

.content-description {
    white-space: pre-wrap;

}

#resultsForm {
    display: flex;
    flex-direction: column;
    display: flex;
    overflow-y: scroll;
    overflow-x: scroll;
    height: 85%;
    width: 100%;
    color: #eeeeee;
    font-size: 1rem;
    padding: 1rem 2rem;
}

#formConti {
    padding: 0 5rem;
    margin: 2rem 0;
}

.save-btn {
    margin: 1rem 0;
}

#subHeading {
    text-align: center;
    color: #f1c40f;
    font-weight: bold;
    font-size: 1.2rem;
}

.linkDisplay {
    display: block;
    font-size: 1.2rem;
}

.noDisplay {
    display: none;
    align-items: center;
}

@media (max-width: 540px) {
    .popup-content>Table th{ 
        /* width: 5rem; */
    } 
    tr {
        width: 100%;
    }   
    th {
        width: 9rem;
    }
    .popup-body {
        width: 90%;
    }
}
.sports_sportGridsContainer__3YiDC {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.sports_sportGridsContainer__3YiDC a {
    width: 90%;
    max-width: 300px;
    background-color: #d9e2c8;
    color: #899178;
    padding: 30px 50px;
    margin: 10px;
    text-align: center;
    text-decoration: none;
    transition: all 0.3s ease;
}

.sports_sportGridsContainer__3YiDC a:hover {
    background-color: #c7ceb8;
    transition: all 0.3s ease;
}


.badminton_cardContainer__1P-qo {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 90%;
    margin: 30px auto;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);
    padding: 20px 40px;
    border-radius: 5px;
}

.badminton_cardContainer__1P-qo .badminton_timer__3ccwE {
    font-weight: bold;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.badminton_cardContainer__1P-qo .badminton_header__54GXn {
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.badminton_cardContainer__1P-qo .badminton_body__2A8Ks {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.badminton_cardContainer__1P-qo .badminton_body__2A8Ks .badminton_vs__3FDtg {
    margin: 10px auto;
}

.badminton_cardContainer__1P-qo .badminton_body__2A8Ks .badminton_eachTeamContainer__1gVi5 {
    text-align: center;
}

.badminton_cardContainer__1P-qo .badminton_body__2A8Ks .badminton_eachTeamContainer__1gVi5 .badminton_eachTeam__OCEPH {
    font-size: 24px;
    font-weight: 600;
}

.badminton_cardContainer__1P-qo .badminton_body__2A8Ks .badminton_eachTeamContainer__1gVi5 .badminton_teamScore__1rn_l {
    font-size: 20px;
    margin: 5px;
}

.badminton_cardContainer__1P-qo .badminton_body__2A8Ks .badminton_eachTeamContainer__1gVi5 .badminton_scorers__3NQ5I {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
}

.badminton_cardContainer__1P-qo .badminton_body__2A8Ks .badminton_eachTeamContainer__1gVi5 .badminton_scorers__3NQ5I img {
    height: 15px;
    margin: 5px;
}

.badminton_cardContainer__1P-qo .badminton_footer__3Uo_S {
    text-align: center;
    font-size: 16px;
    margin: 10px 5px;
    padding: 10px 5px;
}

.badminton_cardContainer__1P-qo .badminton_footer__3Uo_S .badminton_formationBtnContainer__t4F0c {
    padding: 12px;
}

.badminton_cardContainer__1P-qo .badminton_footer__3Uo_S .badminton_formationBtn__1cwaj {
    padding: 10px;
    border: 0;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    background-color: aquamarine;
    cursor: pointer;
    transition: all 0.2s ease;
}

.badminton_cardContainer__1P-qo .badminton_footer__3Uo_S .badminton_formationBtn__1cwaj:hover {
    background-color: rgb(82, 204, 163);
    transition: all 0.2s ease;
}


.badminton_cardContainer__1P-qo .badminton_footer__3Uo_S .badminton_bestPlayer__1Rvjt {
    border: 1px solid gold;
    padding: 20px;
    margin: 10px;
}

.badminton_cardContainer__1P-qo .badminton_footer__3Uo_S .badminton_bestPlayer__1Rvjt .badminton_iconContainer__11vt0 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
}

.badminton_cardContainer__1P-qo .badminton_footer__3Uo_S .badminton_bestPlayer__1Rvjt .badminton_bestPlayerName__24_0O {
    font-size: 16px;
}

.badminton_cardContainer__1P-qo .badminton_footer__3Uo_S .badminton_bestPlayer__1Rvjt .badminton_awardIcon__1SXfk {
    color: gold;
    margin: 0 5px;
}

.basketball_cardContainer__3IOhX {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 90%;
    margin: 30px auto;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);
    padding: 20px 40px;
    border-radius: 5px;
}

.basketball_cardContainer__3IOhX .basketball_timer__3bU88 {
    font-weight: bold;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.basketball_cardContainer__3IOhX .basketball_header__1xnm7 {
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.basketball_cardContainer__3IOhX .basketball_body__rBYPl {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.basketball_cardContainer__3IOhX .basketball_body__rBYPl .basketball_vs__YtZJM {
    margin: 10px auto;
}

.basketball_cardContainer__3IOhX .basketball_body__rBYPl .basketball_eachTeamContainer__1ZtHn {
    text-align: center;
}

.basketball_cardContainer__3IOhX .basketball_body__rBYPl .basketball_eachTeamContainer__1ZtHn .basketball_eachTeam__4MCo5 {
    font-size: 24px;
    font-weight: 600;
}

.basketball_cardContainer__3IOhX .basketball_body__rBYPl .basketball_eachTeamContainer__1ZtHn .basketball_teamScore__2Nbc9 {
}

.basketball_cardContainer__3IOhX .basketball_body__rBYPl .basketball_eachTeamContainer__1ZtHn .basketball_scorers__1tcUZ {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
}

.basketball_cardContainer__3IOhX .basketball_body__rBYPl .basketball_eachTeamContainer__1ZtHn .basketball_scorers__1tcUZ img {
    height: 15px;
    margin: 5px;
}

.basketball_cardContainer__3IOhX .basketball_footer__1h13l {
    text-align: center;
    font-size: 18px;
}

.basketball_cardContainer__3IOhX .basketball_footer__1h13l .basketball_formationBtnContainer__2CqMn {
    padding: 12px;
}

.basketball_cardContainer__3IOhX .basketball_footer__1h13l .basketball_formationBtn__2z6qG {
    padding: 10px;
    border: 0;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    background-color: aquamarine;
    cursor: pointer;
    transition: all 0.2s ease;
}

.basketball_cardContainer__3IOhX .basketball_footer__1h13l .basketball_formationBtn__2z6qG:hover {
    background-color: rgb(82, 204, 163);
    transition: all 0.2s ease;
}


.basketball_cardContainer__3IOhX .basketball_footer__1h13l .basketball_bestPlayer__c2pW3 {
    border: 1px solid gold;
    padding: 20px;
    margin: 10px;
}

.basketball_cardContainer__3IOhX .basketball_footer__1h13l .basketball_bestPlayer__c2pW3 .basketball_iconContainer__K9oYx {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
}

.basketball_cardContainer__3IOhX .basketball_footer__1h13l .basketball_bestPlayer__c2pW3 .basketball_bestPlayerName__2NMoR {
    font-size: 16px;
}

.basketball_cardContainer__3IOhX .basketball_footer__1h13l .basketball_bestPlayer__c2pW3 .basketball_awardIcon__1ULhk {
    color: gold;
    margin: 0 5px;
}

.basketball_cardContainer__3IOhX .basketball_quarterContainer__38iUM .basketball_headingContainer__mUtJA {
    text-align: center;
    margin: 8px;
    font-size: 18px;
    font-weight: bold;
}

.basketball_cardContainer__3IOhX .basketball_quarterContainer__38iUM .basketball_quarterScoreContainer__d-7ra {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 20px;
}
.cricket_matchesSection__2pttW {
    align-self: stretch;
}

.cricket_matchesSection__2pttW h1 {
    text-align: center;
}

.cricket_matchesNavMenu__28F4W {
    display: flex;
    justify-content: center;
}

.cricket_matchesNavMenu__28F4W .cricket_navItem__3mURg {
    flex-grow: 1;
    text-align: center;
    border: 1px solid red;
    padding: 10px;
    border-radius: 5px;
    margin: 2px;
    transition: all 0.3s ease;
}

.cricket_matchesNavMenu__28F4W .cricket_navItem__3mURg.cricket_active__33DzX,
.cricket_matchesNavMenu__28F4W .cricket_navItem__3mURg:hover {
    background-color: red;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;
}

@media (min-width: 540px) {
    .cricket_matchesSection__2pttW h1 {
        text-align: left;
    }
    .cricket_matchesSection__2pttW>div>h4{
        font-size: 2rem;
    }
}

.pointsTable_container__2Ai8b {
    width: 80%;
    margin: 10px auto;
    max-width: 800px;
    overflow-x: auto;
}

.pointsTable_container__2Ai8b select {
    margin: 5px 0;
    color: #899178;
    font-size: 16px;
    font-weight: bold;
    background-color: #d9e2c8;
}

.pointsTable_container__2Ai8b table {
    width: 100%;
}


.pointsTable_container__2Ai8b table {
    width: 100%;
}

.pointsTable_container__2Ai8b table thead tr {
    color: #899178;
    background-color: #d9e2c8;
    border-bottom: 0;
}

.pointsTable_container__2Ai8b table tr {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #98a77b;
}

.pointsTable_container__2Ai8b table td {
    padding: 10px;
    text-align: left;
    font-size: 16px;
    min-width: 40px;
}

.pointsTable_container__2Ai8b table .pointsTable_team__20Dhw {
    flex-grow: 1;
}

.pointsTable_loader__3lYUQ{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 540px) {
    .pointsTable_container__2Ai8b{
        width: 100%;
    }
}

.highlight_highlightCard__Ej98c {
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);
    padding: 20px 80px;
    text-align: center;
    border-radius: 10px;
    margin: 10px auto;
}

.highlight_topContainer__11DvN .highlight_date__1vk7d {
    text-align: center;
    font-size: 16px;
}

.highlight_scoreContainer__24NLZ {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
}

.highlight_scoreContainer__24NLZ .highlight_upcomingTeamContainer__1m6oh {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.highlight_scoreContainer__24NLZ .highlight_teamContainer__3ENvf .highlight_teamName__1IoCM {
    font-size: 34px;
    font-weight: bold;
}

.highlight_scoreContainer__24NLZ .highlight_teamContainer__3ENvf .highlight_overs__26Opu {
    font-size: 16px;
}

.highlight_scoreContainer__24NLZ .highlight_vs__2F2IK {
    padding: 30px;
}

.highlight_bottomContainer__2WUkp .highlight_description__3SjAE,
.highlight_topContainer__11DvN .highlight_description__3SjAE {
    font-size: 16px;
}

.highlight_bottomContainer__2WUkp .highlight_bestPlayer__Th_dE {
    border: 1px solid gold;
    padding: 20px;
    margin: 10px;
}

.highlight_bottomContainer__2WUkp .highlight_bestPlayer__Th_dE .highlight_iconContainer__3pXq9 {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
}

.highlight_bottomContainer__2WUkp .highlight_bestPlayer__Th_dE .highlight_bestPlayerName__-Ss1x {
    font-size: 16px;
}

.highlight_bottomContainer__2WUkp .highlight_bestPlayer__Th_dE .highlight_awardIcon__1_vgV {
    color: gold;
    margin: 0 5px;
}

.highlight_bottomContainer__2WUkp .highlight_scorecardBtn__3kM8Y {
    padding: 10px;
    border: 0;
    border-radius: 5px;
    color: #fff;
    background-color: aquamarine;
    cursor: pointer;
    transition: all 0.2s ease;
}

.highlight_bottomContainer__2WUkp .highlight_scorecardBtn__3kM8Y:hover {
    background-color: rgb(82, 204, 163);
    transition: all 0.2s ease;
}

.highlight_loader__2vI9n {
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 540px) {
    .highlight_highlightCard__Ej98c {
        padding: 2rem;
    }

    .highlight_scoreContainer__24NLZ {
        font-size: 3rem;
    }

    * .highlight_teamContainer__3ENvf {
        font-size: 3rem;
    }

    .highlight_teamName__1IoCM {
        font-size: 3rem;
    }

    .highlight_bestPlayerName__-Ss1x {
        font-size: 1.5rem;
        box-sizing: border-box;
    }

    .highlight_bottomContainer__2WUkp .highlight_bestPlayer__Th_dE {
        border: 1px solid gold;
        padding: 1rem;
        margin: 0;
    }

    .highlight_scorecardBtn__3kM8Y {
        margin: 1rem;
    }
}
.scorecard_modal__r93t_ {
    z-index: 1000;
    width: 90%;
    max-width: 700px;
    margin: 10vh auto;
    height: 80vh;
    max-height: 80vh;
    overflow: hidden;
    background-color: white;
    display: flex!important;
}

.scorecard_modal__r93t_ .scorecard_container__25xtM {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;
    padding: 25px;
    overflow-y: auto;
}

.scorecard_modal__r93t_ .scorecard_header__2B-h6 {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-bottom: 1px solid #98a77b;
    font-size: 28px;
    font-weight: bold;
    align-items: center;
}

.scorecard_modal__r93t_ .scorecard_header__2B-h6 .scorecard_liIcon__2p93U {
    cursor: pointer;
}

.scorecard_modal__r93t_ .scorecard_body__3Lnjl {
    padding: 10px;
    flex-grow: 1;
}

.scorecard_modal__r93t_ .scorecard_footer__1dDx5 {
    display: flex;
    flex-direction: column;
}

.scorecard_modal__r93t_ .scorecard_button__2UvMw {
    padding: 10px;
    text-align: center;
    background-color: #b2c58c;
    border: 0;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    transition: all 0.3s ease;
}

.scorecard_modal__r93t_ .scorecard_button__2UvMw:hover {
    background-color: rgb(149, 170, 103);
    cursor: pointer;
    transition: all 0.3s ease;
}

.scorecard_resultLine__3TcLJ {
    font-size: 16px;
}

.scorecard_scoreCard__1ML02 {
    margin: 20px auto;
}

.scorecard_scoreCard__1ML02 .scorecard_heading__3qY2I {
    background-color: rgb(247, 28, 28);
    color: #fff;
    margin: 0;
    border: 0;
    padding: 10px;
    font-size: 18px;
    text-align: center;
}

.scorecard_scoreCard__1ML02 table {
    width: 100%;
}

.scorecard_scoreCard__1ML02 table thead tr {
    color: #899178;
    background-color: #d9e2c8;
    border-bottom: 0;
}

.scorecard_scoreCard__1ML02 table tr {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #98a77b;
}

.scorecard_scoreCard__1ML02 table td {
    padding: 10px;
    text-align: left;
    font-size: 16px;
    min-width: 40px;
}

.scorecard_scoreCard__1ML02 table .scorecard_batsman__ph3RL {
    flex-grow: 1;
}

.scorecard_scoreCard__1ML02 table .scorecard_batsman__ph3RL span {
    color: #a1a19c;
    font-size: 14px;
}

.football_cardContainer__2-EFb {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 90%;
    margin: 30px auto;
    box-shadow: 0 5px 10px rgba(154, 160, 185, 0.05), 0 15px 40px rgba(166, 173, 201, 0.2);
    padding: 20px 40px;
    border-radius: 5px;
}

.football_cardContainer__2-EFb .football_timer__IVC_e {
    font-weight: bold;
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.football_cardContainer__2-EFb .football_header__2FSe1 {
    display: flex;
    justify-content: center;
    font-size: 16px;
}

.football_cardContainer__2-EFb .football_body__27stS {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.football_cardContainer__2-EFb .football_body__27stS .football_vs__1wAs4 {
    margin: 10px auto;
}

.football_cardContainer__2-EFb .football_body__27stS .football_eachTeamContainer__2WXmi {
    text-align: center;
}

.football_cardContainer__2-EFb .football_body__27stS .football_eachTeamContainer__2WXmi .football_eachTeam__3FsHD {
    font-size: 24px;
    font-weight: 600;
}

.football_cardContainer__2-EFb .football_body__27stS .football_eachTeamContainer__2WXmi .football_scorers__9yubl {
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 14px;
}

.football_cardContainer__2-EFb .football_body__27stS .football_eachTeamContainer__2WXmi .football_scorers__9yubl img {
    height: 15px;
    margin: 5px;
}

.football_cardContainer__2-EFb .football_footer__3jHH4 {
    text-align: center;
    font-size: 18px;
}

.football_cardContainer__2-EFb .football_footer__3jHH4 .football_formationBtnContainer__9n6JS {
    padding: 12px;
}

.football_cardContainer__2-EFb .football_footer__3jHH4 .football_formationBtn__ly-Uz {
    padding: 10px;
    border: 0;
    border-radius: 5px;
    color: #fff;
    text-decoration: none;
    background-color: aquamarine;
    cursor: pointer;
    transition: all 0.2s ease;
}

.football_cardContainer__2-EFb .football_footer__3jHH4 .football_formationBtn__ly-Uz:hover {
    background-color: rgb(82, 204, 163);
    transition: all 0.2s ease;
}


.football_cardContainer__2-EFb .football_footer__3jHH4 .football_bestPlayer__3T7Sy {
    border: 1px solid gold;
    padding: 20px;
    margin: 10px;
}

.football_cardContainer__2-EFb .football_footer__3jHH4 .football_bestPlayer__3T7Sy .football_iconContainer__FzgOV {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 400;
}

.football_cardContainer__2-EFb .football_footer__3jHH4 .football_bestPlayer__3T7Sy .football_bestPlayerName__CIDds {
    font-size: 16px;
}

.football_cardContainer__2-EFb .football_footer__3jHH4 .football_bestPlayer__3T7Sy .football_awardIcon__1JPT1 {
    color: gold;
    margin: 0 5px;
}

.football_quarterHeading__3X6Gs {
    text-align: center;
}
@media only screen and (min-width: 725px) {
    .certificateimage {
        text-align: center;
    }

    .certificateimage img {
        width: 100%;
        height: 50%;
    }

    .certifpreview_nav h1 {
        font-size: 2.25rem;
    }

    .downloadBtn {
        font-size: 1.25rem;
        width: auto;
        margin: 0 1rem;
    }
}

@media only screen and (max-width: 724px) {
    .certificateimage {
        text-align: center;
    }

    .certificateimage img {
        width: 80vw;
        height: auto;
    }

    .certifpreview_nav h1 {
        font-size: 1.6rem;
    }

    .downloadBtn {
        font-size: 1.25rem;
        width: 15rem;
    }
}

.downloadBtn {
    color: #f1c40f;
    background-color: transparent;
    /* background-color: #F1C40F; */
    padding: 10px;
    font: 1rem;
    transition: all 0.2s;
    /* border: none; */
    border: 1px solid #f1c40f;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer !important;
}

.downloadBtn:hover {
    background-color: #f1c40f;
    color: white;
    cursor: pointer !important;
}

.gymk_logo,
.kgp_logo {
    width: 6rem;
    margin: 0 0.6rem;
}

.certifpreview_body hr {
    color: #f1c40f;
    width: 80vw;
    margin: 0.5rem auto;
    border: 0.2rem solid #f1c40f;
}

.certifpreview_share {
    width: 40vw;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.certifshare_text {
    color: white;
    font-size: 1.25rem;
    font-weight: 600;
}

.certifshare_link i {
    color: black;
    background-color: #f1c40f;
    border-radius: 50%;
    width: 2rem;
    margin: 0 0.5rem;
    padding: 0.5rem;
    font-size: 1.25rem;
}

.error-msg {
    width: 70vw;
    color: white;
    margin-top: 3rem;
    font-size: 1.2rem;
}

.error-msg ol li {
    list-style: outside decimal !important;
}

.error-msg a {
    padding: 0 !important;
    color: white;
    display: inline-block;
    letter-spacing: inherit;
    text-decoration: underline;
    text-transform: none;
    transition: none;
    position: inherit;
    z-index: 1;
    overflow: inherit;
}

.error-msg a:hover {
    cursor: pointer;
}

.letter-to-me-container {
  display: flex;
  flex-wrap: wrap; /* Allow columns to stack on smaller screens */
  /* height: 95vh; Full viewport height */
  width: 100%;
  overflow: hidden;
}

.left-column {
  position: relative;
  width: 48%;
  background-image: url(/static/media/iitkgp.8d87f784.jpg); 
  background-color: black;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.desc-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f7f9f8;
}

.left-column h2 {
  position: relative;
  margin-top: 16px;
  font-size: 2.7rem;
  font-weight: 900;
  margin-bottom: 28px;
  text-align: center;
}

.left-column h2 img {
  position: absolute;
  top: -40px;
  left: -60px;
  width: 100px;
  height: 80px;
  transform: rotate(340deg);
}

.left-column p {
  font-size: 1.1rem;
  margin: 10px 0;
  line-height: 1.5;
}

.right-column {
  width: 52%;
  background-color: #1c272b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  height: 100%; /* Ensure the form container takes up full height */
}

form {
  display: flex;
  flex-direction: column;
  width: 100%;
  grid-gap: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
  padding: 0 60px;
  height: 100vh; /* Make the form take up the full viewport height */
  box-sizing: border-box; /* To ensure padding doesn’t affect the height */
}

form input,
form textarea,
form button {
  background-color: white;
  width: 100%;
  padding: 12px;
  border: 1px solid white;
  border-radius: 4px;
  font-size: 1rem;
}

.box1 {
  width: 100%;
  display: flex;
  grid-gap: 16px;
  gap: 16px;
  align-items: center;
}

#sendDate,
#rollNo {
  width: 60%;
}

#message {
  height: 220px;
}

form button {
  background-color: #68d128;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  border: none;
  transition: background-color 0.3s ease;
}

form button:hover {
  background-color: #4eda0d;
}

.error-message {
  color: #ff4d4d;
  font-size: 0.9rem;
}

.server-message {
  color: #256b27;
  font-size: 0.9rem;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .letter-to-me-container {
    height: auto; /* Allow height to adjust */
  }

  .left-column,
  .right-column {
    width: 100%; /* Full width for each column */
    height: auto; /* Adjust height automatically */
  }

  /* Hide left column on smaller screens */
  .left-column {
    display: none;
  }

  form {
    padding: 0 40px; /* Adjust padding for medium screens */
    height: 100vh; /* Ensure form takes up full height */
  }

  #message {
    height: 200px; /* Adjust text area height */
  }
}

@media (max-width: 768px) {
  .letter-to-me-container {
    flex-direction: column; /* Stack columns vertically */
  }

  /* Hide left column on smaller screens */
  .left-column {
    display: none;
  }

  .right-column {
    width: 100%; /* Full width for the form */
  }

  form {
    padding: 0 20px; /* Reduce padding for smaller screens */
    height: 100vh; /* Ensure form takes up full height */
  }

  #message {
    height: 180px; /* Adjust text area height */
  }
}

@media (max-width: 480px) {
  .left-column {
    display: none; /* Completely hide the left column */
  }

  .right-column {
    width: 100%; /* Full width for the form */
  }

  form input,
  form textarea,
  form button {
    font-size: 0.9rem; /* Adjust input sizes */
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .box1 {
    flex-direction: column; /* Stack inputs vertically */
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
  }

  #sendDate,
  #rollNo {
    width: 100%; /* Make inputs full-width on small screens */
  }
}

/* Container for the form */
.society-form-container {
    max-width: 600px;
    width: 90%; /* Ensure it takes up most of the screen width */
    margin: 50px auto; /* Center it horizontally with a top margin */
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* Form title styling */
.society-form-container h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
    font-size: 24px;
}

/* Form styling */
.society-form {
    display: flex;
    flex-direction: column;
    width: 100%; /* Ensure the form takes up all available space */
}

/* Form group styling */
.form-group {
    margin-bottom: 15px;
}

/* Label styling */
.form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #555;
}

/* Input and textarea styling */
.form-group input,
.form-group textarea {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box; /* Include padding in element width */
}

/* Textarea custom styling */
textarea {
    resize: none;
    height: 120px; /* Ensure textarea has a minimum height */
}

/* File input styling */
.society-form input[type="file"] {
    padding: 5px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Submit button styling */
.submit-button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Submit button hover effect */
.submit-button:hover {
    background-color: #0056b3;
}

/* Make form responsive */
@media (max-width: 768px) {
    .society-form-container {
        width: 90%; /* Full width on smaller screens */
        padding: 15px; /* Reduce padding on smaller screens */
    }

    .society-form-container h1 {
        font-size: 20px; /* Reduce font size */
    }

    .form-group input,
    .form-group textarea,
    .submit-button {
        font-size: 16px; /* Increase font size for better touch interaction */
    }

    textarea {
        height: 100px; /* Slightly smaller height for mobile */
    }
}

@media (max-width: 480px) {
    .society-form-container {
        padding: 10px; /* Reduce padding further on very small screens */
    }

    .submit-button {
        font-size: 14px; /* Make button text smaller */
    }

    .form-group input,
    .form-group textarea {
        padding: 10px; /* Smaller padding for small devices */
    }
}

